


<div>

    <div class="card border border-info border-1">
        <div class="bg-info p-2">
            <h1 class="card-title text-white">Lista de capacitaciones</h1>
        </div>
        <div class="card-body">
        <div class="container-btn mt-1 mb-3">
            <div>
                <div ngbDropdown placement="top-end" class="d-inline-block" style="margin-right: 10px;">
                    <button type="button" class="btn btn-primary" (click)="showDialog()">Agregar nueva Temporada</button>

                </div>
            </div>
            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                placeholder="Global Search" />
        </div>

        <p-table #dt [value]="capacitaciones" [rows]="10"
            [globalFilterFields]="['name','current','camp_start','camp_end','public_price']"
            [(selection)]="selectCapcitacion" [rowHover]="true" dataKey="id"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [showCurrentPageReport]="true">

            <ng-template pTemplate="header">
                <tr>
                   
                    <th pSortableColumn="name">Nombre  <p-sortIcon field="name"
                            style="font-size: 10px;"></p-sortIcon></th>
                    <th pSortableColumn=" current">Activo <p-sortIcon field="current"></p-sortIcon></th>
                        <th></th>
                        <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customer>
                <tr>
                    
                    <td>
                        {{customer.name}}
                    </td>
                    <td>

                        <i class="bx bxs-calendar-check" *ngIf="customer.current" style="color: green;"></i> <i
                            class="bx bxs-calendar-x" style="color: red;" *ngIf="!customer.current"></i>

                    </td>
                
                    
                    <td>
                        <button class="btn btn-warning" (click)="update(customer)">Editar</button>

                    </td>
               <td>
                        <button class="btn btn-danger"
                            (click)="deletModal(customer.name,customer.id)">Eliminar</button>
                    </td>
                </tr>
            </ng-template>

        </p-table>
    </div>
    </div>





</div>

<p-dialog header="Title" [(visible)]="display" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
    [style]="{width: '50vw'}">
    <ng-template pTemplate="header">
        <h2>
            Agregar nueva temporada
        </h2>
    </ng-template>
    <div class="col">
        <form [formGroup]="addTrainingForm" (ngSubmit)="onSave()">

            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Nombre</label>
                <div class="col-sm-9">
                    <input input type="text" class="form-control" id="" formControlName="name">
                </div>

            </div>
          
           
           
            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="current" checked
                    formControlName="current">
                <label class="form-check-label" for="current">Activo</label>
            </div>
            
        </form>
    </div>

    <ng-template pTemplate="footer">
        <div class="button">
            <button type="submit" class="btn btn-primary p-3" (click)="onSave()" *ngIf="!showButton">
                Guardar
            </button>
            <div class="spinner-border text-primary" role="status" *ngIf="showButton">
                
              </div>
        </div>
    </ng-template>
</p-dialog>


<p-dialog header="Title" [(visible)]="display2" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
    [style]="{width: '50vw'}">
    <ng-template pTemplate="header">
        <h2>
            Editar temporada
        </h2>
    </ng-template>
    <div class="col">
        <form [formGroup]="addTrainingForm" (ngSubmit)="onSave()">

            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Nombre</label>
                <div class="col-sm-9">
                    <input input type="text" class="form-control" id="" formControlName="name">
                </div>

            </div>
          
           
           
            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="current" checked
                    formControlName="current">
                <label class="form-check-label" for="current">Activo</label>
            </div>


        </form>
    </div>
    <ng-template pTemplate="footer">
        <button type="button" class="btn btn-outline-secondary" (click)="closeModal2()">Cancelar</button>
        <button type="button" class="btn btn-danger" (click)="keepUpdate()" *ngIf="!showButton">Guardar</button>
        <div class="spinner-border text-primary" role="status" *ngIf="showButton">
                
        </div>
    </ng-template>
</p-dialog>

<p-dialog header="Title" [(visible)]="display3" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
    [style]="{width: '50vw'}">
    <ng-template pTemplate="header">
        <h2>
            Eliminar divisa
        </h2>
    </ng-template>
    <div class="col">
        {{TextElimint}}
        <div class="alert alert-danger" role="alert" *ngIf="statuAgrgado">
            Se elimino correctamente
        </div>


    </div>
    <ng-template pTemplate="footer">
        <button type="button" class="btn btn-outline-secondary" (click)="this.display3 = false">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="delet()">Guardar</button>
    </ng-template>
</p-dialog>
