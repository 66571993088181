import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CatalogosService } from 'src/services/catalogos.service';
import { LocacionService } from './locacion.service';

@Component({
  selector: 'app-locacion',
  templateUrl: './locacion.component.html',
  styleUrls: ['./locacion.component.scss']
})
export class LocacionComponent implements OnInit {
  @ViewChild('address') address: ElementRef;
  @ViewChild('name') name: ElementRef;
  @ViewChild('url') url: ElementRef;
  @ViewChild('contact') contact: ElementRef;
  @ViewChild('phone') phone: ElementRef;
  @ViewChild('cellphone') cellphone: ElementRef;
  @ViewChild('email') email: ElementRef;
  @ViewChild('contact_second_name') contact_second_name: ElementRef;
  @ViewChild('contact_second_phone') contact_second_phone: ElementRef;
  @ViewChild('contact_second_cellphone') contact_second_cellphone: ElementRef;
  @ViewChild('contact_second_email') contact_second_email: ElementRef;
  @ViewChild('contact_third_name') contact_third_name: ElementRef;
  @ViewChild('contact_third_phone') contact_third_phone: ElementRef;
  @ViewChild('contact_third_cellphone') contact_third_cellphone: ElementRef;
  @ViewChild('contact_third_email') contact_third_email: ElementRef;

  @ViewChild('addressU') addressU: ElementRef;
  @ViewChild('nameU') nameU: ElementRef;
  @ViewChild('urlU') urlU: ElementRef;
  @ViewChild('contactU') contactU: ElementRef;
  @ViewChild('phoneU') phoneU: ElementRef;
  @ViewChild('cellphoneU') cellphoneU: ElementRef;
  @ViewChild('emailU') emailU: ElementRef;
  @ViewChild('contact_second_nameU') contact_second_nameU: ElementRef;
  @ViewChild('contact_second_phoneU') contact_second_phoneU: ElementRef;
  @ViewChild('contact_second_cellphoneU') contact_second_cellphoneU: ElementRef;
  @ViewChild('contact_second_emailU') contact_second_emailU: ElementRef;
  @ViewChild('contact_third_nameU') contact_third_nameU: ElementRef;
  @ViewChild('contact_third_phoneU') contact_third_phoneU: ElementRef;
  @ViewChild('contact_third_cellphoneU') contact_third_cellphoneU: ElementRef;
  @ViewChild('contact_third_emailU') contact_third_emailU: ElementRef;




  listcatalogos: any = [];
  selectCatalogos: any;
  items: any;
  display: boolean = false;
  display2: boolean = false;
  display3: boolean = false;
  idDalete = 0;
  updateId = 0;
  spinner = false;
  text: any;
  TextElimint = "";
  formFood: FormGroup;
  date: Date = new Date();
  statuAgrgado = false;
  cat: any = {
    '0': 'ninguno',
    '1': 'Staff',
    '2': 'Acampador',
    '3': 'Staff y Acampador'
  }
  capa = {
    name: ''
  }
  breadCrumbItems: Array<{}>;
  selectedCities: any[] = [];
  constructor(private catalogos: LocacionService, private _FormBuild: FormBuilder, private render: Renderer2) {
    this.getCatalogos()
  }

  ngOnInit(): void {
    this.formFood = this._FormBuild.group({
      "name": ["",Validators.required],
      "phone": ["",Validators.required],
      "email": ["",Validators.required],
      "contact": ["",Validators.required],
      "address": ["",Validators.required],
      "url": ["",Validators.required],
      "active": [true],
      "created_at": [this.date]
    })
  }


  showDialog() {
    this.display = true;
    this.resetInput();
  }
  showDialog2() {
    this.display2 = true;
  }
  closeModal() {
    this.display = false;

  }

  closeModal3() {
    this.display3 = false;

  }
  closeModal2() {
    this.display2 = false;

  }

  getCatalogos() {
    this.catalogos.getSchool().subscribe((res: any) => {
      this.listcatalogos = res.data;

      console.log(this.listcatalogos);
    });


  }

  guardar() {
    if (this.formFood.valid) {
      this.spinner= true;
      this.catalogos.postSchool(this.formFood.value).subscribe((res: any) => {
        console.log(res);
        this.spinner= false;

        this.getCatalogos();
        this.statuAgrgado = true;
        this.resteValu();
        this.resetInput();
        setTimeout(() => {
          this.statuAgrgado = false;
          this.closeModal();
        }, 400);

      }, error => {
        this.spinner= false;
        alert('No se pudo Agregar')
      })
    } else {
      this.getContact_third_email();
      this.getContact_third_cellphone();
      this.getContact_third_phone();
      this.getContact_third_name();

      this.getContact_second_email();
      this.getContact_second_cellphone();
      this.getContact_second_phone();
      this.getContact_second_name();

      this.getEmail();
      this.getCellphone();
      this.getPhone();
      this.getContact();
      this.getUrl();
      this.getAddress();
      this.getName();






    }


  }

  resteValu() {
    this.formFood.reset();
    this.formFood.patchValue({
      "active": false,
       "created_at":this.date
    })
  }
  guardarOrder() {
    let a = []
    this.listcatalogos.forEach((element, index) => {
      a.push({ id: element.id, order: index })
    });
    this.getCatalogos();
  }

  update(item) {
    console.log(item);

    this.showDialog2();
    this.updateId = item.id;
    this.formFood.patchValue({
      "name": item.name,
      "phone": item.phone,
      "email": item.email,
      "contact": item.contact,
      "address": item.address,
      "url": item.url,
      "active": item.active ,

    })
    this.cargarValidadores();

  }

  keepUpdate() {
    if (this.formFood.valid) {
      this.spinner= true;
      this.catalogos.updateSchool(this.formFood.value, this.updateId).subscribe((res: any) => {
        console.log(res);
        this.spinner= false;
        this.getCatalogos();
        this.statuAgrgado = true;
        this.resteValu();
        this.resetInput();
        setTimeout(() => {
          this.statuAgrgado = false;
          this.spinner= false;
          this.closeModal2();
        }, 400);

      }, error => {
        console.log(error);

        alert('No se pudo Agregar')
      })
    } else {
      this.getContact_third_email();
      this.getContact_third_cellphone();
      this.getContact_third_phone();
      this.getContact_third_name();

      this.getContact_second_email();
      this.getContact_second_cellphone();
      this.getContact_second_phone();
      this.getContact_second_name();

      this.getEmail();
      this.getCellphone();
      this.getPhone();
      this.getContact();
      this.getUrl();
      this.getAddress();
      this.getName();
    }

  }

  cargarValidadores() {
    this.getContact_third_email();
    this.getContact_third_cellphone();
    this.getContact_third_phone();
    this.getContact_third_name();

    this.getContact_second_email();
    this.getContact_second_cellphone();
    this.getContact_second_phone();
    this.getContact_second_name();

    this.getEmail();
    this.getCellphone();
    this.getPhone();
    this.getContact();
    this.getUrl();
    this.getAddress();
    this.getName();
  }


  deletModal(name, id) {
    this.idDalete = id;
    // console.log(id);

    this.TextElimint = 'Deseas Eliminar ' + name + '  del catalogo';
    this.display3 = true;

  }

  delet() {
    console.log(this.idDalete, 'aaa');

    this.catalogos.deleteSchool(this.idDalete).subscribe((res: any) => {
      if (res.status.status == true) {

        this.statuAgrgado = true;
        this.resteValu();
        this.getCatalogos();
        setTimeout(() => {
          this.statuAgrgado = false;
          this.closeModal3();
        }, 400);
      } else {
        alert('No se pudo Eliminar esta en uso')

      }
    }, error => {
      alert('No se pudo Eliminar')
    })
  }

  validateFormField(elementRef: any, name): void {
    if (this.formFood.get(name).valid) {
      this.render.removeClass(elementRef.nativeElement, "is-invalid");
      this.render.addClass(elementRef.nativeElement, "is-valid");
    } else {
      this.render.removeClass(elementRef.nativeElement, "is-valid");
      this.render.addClass(elementRef.nativeElement, "is-invalid");
      elementRef.nativeElement.focus();
    }
  }

  
  resetInput() {
 
  }

  getName() {
    this.validateFormField(this.name, 'name')
  }
  getUrl() {
    this.validateFormField(this.url, 'url')
  }
  getAddress() {
    this.validateFormField(this.address, 'address')
  }
  getContact() {
    this.validateFormField(this.contact, 'contact')
  }
  getPhone() {
    this.validateFormField(this.phone, 'phone')
  }
  getCellphone() {
    this.validateFormField(this.cellphone, 'cellphone')
  }
  getEmail() {
    this.validateFormField(this.email, 'email')
  }
  getContact_second_name() {
    this.validateFormField(this.contact_second_name, 'contact_second_name')
  }
  getContact_second_phone() {
    this.validateFormField(this.contact_second_phone, 'contact_second_phone')
  }
  getContact_second_cellphone() {
    this.validateFormField(this.contact_second_cellphone, 'contact_second_cellphone')
  }
  getContact_second_email() {
    this.validateFormField(this.contact_second_email, 'contact_second_email')
  }
  getContact_third_name() {
    this.validateFormField(this.contact_third_name, 'contact_third_name')
  }
  getContact_third_phone() {
    this.validateFormField(this.contact_third_phone, 'contact_third_phone')
  }
  getContact_third_cellphone() {
    this.validateFormField(this.contact_third_cellphone, 'contact_third_cellphone')
  }
  getContact_third_email() {
    this.validateFormField(this.contact_third_email, 'contact_third_email')
  }


 
}
