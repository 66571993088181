




<div  *ngIf="!table && !display2">
    
      
   
    <div class="col">
        <h1>
            Agregar camper
           </h1> 
        <div class="alert alert-primary mt-2" role="alert" *ngIf="statuAgrgado">
            Se Agrego correctamente
          </div>
        <form [formGroup]="formFood">
            <div class="row">
                <div class="col-12">
    
                    <div class="col-lg-12">
                        <div class="card">
        
                            <div class="card-body">
                                <div class="container-footer-parents">
                                    <h4 class="ms-3">{{textos['CampersPersonalInformation']}}</h4>
                                </div>
                                <div class="mt-5">
                                    <div class="row mb-4">
                                        <label for=""
                                            class="col-sm-3 col-form-label">{{textos['Name']}}</label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control " id="" formControlName="name" name="name" #name (input)="getname()">
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <label for=""
                                            class="col-sm-3 col-form-label">Buscar Padre o tutor</label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control " id=""    [value]="nameParent" disabled="false" (input)="searchparten()">
                                                <button type="button" class="btn btn-primary" (click)="showDialogSearch()" icon="pi pi-info-circle" label="Selecionar  Padre o Tutor">Selecionar  Padre o Tutor</button>
                                            </div>


                                    </div>
                                 
                                    <div class="row mb-4">
                                        <label for="" class="col-sm-3 col-form-label">{{textos['lastname']}}</label>
                                           
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" id=""
                                                formControlName="lastname_father" name="lastname_father" (input)="getlastname_father()" #lastname_father>
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <label for="" class="col-sm-3 col-form-label">{{textos['motherslastname']}}</label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" id=""
                                                formControlName="lastname_mother"  name="lastname_mother" #lastname_mother (input)="getlastname_mother()">
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <label for="" class="col-sm-3 col-form-label">{{textos['email']}}</label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" id=""
                                                formControlName="email"  name="email" #email (input)="getemail()">
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <label for="" class="col-sm-3 col-form-label">{{textos['Gender']}}</label>
        
                                        <div class="col-sm-9">
                                            <select bindLabel="value" bindValue="id" formControlName="gender_id"  style="width: 100%;" (change)="getgender_id()"  class=" form-control" #gender_id>
                                              
                                                <option *ngFor="let item of genders" [value]="item.id" > {{item.value}}</option>
                                            </select>
                                        </div>
                                    </div>
        
                                    <div class="row mb-4 mt-3">
                                        <label for="" class="col-sm-3 col-form-label">{{textos['Birthdate']}}</label>
                                        <div class=" control-label col-sm-9">
                                            
                                                <input type="date" class="form-control" placeholder="dd-mm-yyy"
                                                formControlName="birthday" style="width: 100%;"  (input)="getbirthday()" #birthday>
                                           
                                        </div>
                                    </div>
                                    <div class="foto mt-4">
                                        <div class="container-footer-parents">
                                            <img [src]="photoSelect || 'assets/images/camp/perfil-camper.png'" class="avatar-lg" alt="madre y padres">
                                            
                                        </div>
        
                                        <div class="mt-3 foto"  tabindex="0" id="photo">
                                            <label for="">{{textos['Profilepicture']}}</label>
                                            <input type="file" accept="image/jpeg" (change)="subiendo($event)" >
                                            
            
                                            <label class="mt-2">La foto es obligatoria ,nos ayuda a identificar a los campers,
                                                recomendamos subas una foto reciente y de poco peso, máximo 3Mb debe ser formato jpg</label>
            
                                        </div>
                                        <div *ngIf="spinerPhot">
                                            <div class="alert alert-danger" role="alert" *ngIf="!photoSatus">
                                                Aun no has  seleciona una imagen Recuerda tiene que ser un imagen TIPO JPG
                                               </div>
             
                                               <div class="alert alert-secondary" role="alert" *ngIf="photoSatus">
                                                 Se Agrego corectamente
                                               </div>
                                        </div>
                
                                          <div class="spinner-border" role="status" *ngIf="!spinerPhot">
                                            <span class="visually-hidden">Loading...</span>
                                          </div>
                                      
                                    </div>
                                </div>
                            </div>
        
                        </div>
                        <div class="card">
        
                            <div class="card-body">
                                <div class="container-footer-parents">
                                    <h4 class="ms-3">{{textos['Schoolinformation']}}</h4>
                                </div>
                                <div class="mt-5">
                                    <div class="row mb-4">
                                        <label for="" class="col-sm-3 col-form-label">{{textos['School']}}</label>
                                        <div class="control-label col-sm-9">
                                            <ng-select
                                            name="school_id"
                                            id="school_id"
                                            formControlName="school_id"
                                            [items]="school" 
                                            bindLabel="name"
                                            bindValue="id"
                                            (change)="getschool_id()"
                                            [searchable]="true"
                                            class="form-control">
                                        </ng-select>
                                        
                                          
                                        </div>
                                       
                                    </div>
                                    <div class="row mb-4">
                                        <label for="" class="col-sm-3 col-form-label">{{textos['Otherschool']}}</label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" id="" formControlName="school_other" (input)="getSchool_other()" #school_other>
                                        </div>
                                       
                                    </div>
                                    <div class="row mb-4" >
                                        <label for="" class="col-sm-3 col-form-label">{{textos['Grade']}}</label>
                                        <div class="control-label col-sm-9">
                                          
        
                                            <select name="school_id" id="school_id" formControlName="grade" (change)="getgrade()" class="form-control" #grade>
                                                <option *ngFor="let item of grades" [value]="item.id" >
                                                    {{item.value}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="alert alert-danger mt-2" role="alert" *ngIf="grade.invalid">
                                                                                     Campo requerido
                                          </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            
                        <div class="card">
        
                            <div class="card-body">
                                <div class="container-footer-parents">
                                    <h4 class="ms-3">{{textos['MedicalinformationGeneralMedicalData']}}</h4>
                                </div>
                                <div class="mt-5">
                                    <div class="row mb-4">
                                        <label for="" class="col-sm-3 col-form-label">{{textos['Height']}}</label>
                                        
        
                                        <div class="control-label col-sm-9">
                                            <div class="input-group mb-3">
                                                <input class="form-control" type="number"  min="0" max="200" step="0.5" id="customRange3"   suffix="cm" formControlName="height" 
                                                #height
                                                (input)="getheight()"
                                              />
                                                <div class="input-group-append">
                                                  <span class="input-group-text" id="basic-addon2">CM</span>
                                                </div>
                                            </div>
                                          
                                          </div>
                                    </div>
                                    <div class="row mb-4">
                                        <label for="" class="col-sm-3 col-form-label">{{textos['Weight']}}</label>
                                        <div class="col-sm-9">
                                         
                                                 <div class="input-group mb-3">
                                                    <input type="number" class="form-control" id=""  step="5" min="0" max="200"
                                                    formControlName="weight" #weight
                                                    (input)="getweight()"
                                                   
                                                    >
                                                    <div class="input-group-append">
                                                      <span class="input-group-text" id="basic-addon2">kg</span>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row mb-4">
        
                                        <label for="" class="col-sm-6 col-form-label">
                                            {{textos['Currentvaccines']}}</label>
                                        <div class="container-genero">
                                            <div class="mb-3">
            
            
                                                <div class="form-check form-check-inline" *ngFor="let item of vaccines;index as i">
                                                    <input (click)="vaccinesValu(i)" class="form-check-input" type="checkbox" name="inlineRadioOptions"
                                                        id="inlineRadio1" value="{{item.is_active}}" id="{{item.id}}" [checked]="item.is_active" 
                                                        >
                                                    <label class="form-check-label" for="inlineRadio1">{{item.name}}</label>
                                                </div>
            
            
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <label for="" class="col-sm-3 col-form-label">{{textos['Bloodtype']}}</label>
                                        <div class="control-label col-sm-9">
                                            <select name="school_id" id="school_id" formControlName="blood_type" (change)="getblood_type()" class="form-control" #blood_type>
                                                <option *ngFor="let item of blood_types" [value]="item.id" >
                                                    {{item.value}}
                                                </option>
                                            </select>
                                           
        
                                            <small>{{textos['Ifyoudon']}}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
        
                           
        
        
                        </div>
                        
                        </div>
                        <div class="card-body card">
                            <div class="container-footer-parents">
                                <h4 class="ms-3">{{textos['DiseaseRegistry']}}
                                </h4>
                            </div>
        
                            <h2 style="text-align:center ; margin-top: 30px;">{{textos['Pathologicalhistory']}}</h2>
                            <div class="mt-5">
                                <table class="tabla-medicos" style="width: 100%;">
                                    <thead>
                                        <tr>
                                            <th style="background-color: white;">Nombre</th>
                                            <th style="background-color: white;">{{ textos['CAMPER'] }}</th>
                                            <th style="background-color: white;">{{ textos['FAMILY'] }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of pathological_background; index as i">
                                            <td class="nombre">{{ item.name }}</td>
                                            <td>
                                                <input (click)="pathological_backgroundValu(i)" type="checkbox" style="margin-top:10px ;" value="{{item.is_active}}" [checked]="item.is_active" 
                                                id="{{item.id}}">
                                            </td>
                                            <td>
                                                <input *ngIf="pathological_background_fm[i]" 
                                                       (click)="pathological_background_fmValu(i)" 
                                                       type="checkbox" 
                                                       [checked]="pathological_background_fm[i]?.is_active" 
                                                       id="{{pathological_background_fm[i]?.id}}">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="alert-dismissible alert alert-secondary">
                                    <p style="text-align:justify;" for="">Marca las casillas de las enfermedades que haya
                                        padecido o padezca el camper en la columna camper. En caso de que un familiar haya
                                        padecido alguna de esas enfermedades marca en la columna de familiar</p>
                                </div>
        
        
                                <div class="container-medico mt-5">
                                    <h3>{{textos['Heartproblems']}}</h3>
                                    <textarea
                                        placeholder="En caso de NO padecer ningun problema cardiaco, teclea la palabra NINGUNO"
                                        name="" id="" cols="90" rows="10"
                                        formControlName = "heart_problems"
                                        class="form-control"
                                        (input)="getheart_problems()"
                                         #heart_problems></textarea>
                                </div>
                                <div class="container-medico mt-5">
                                    <h3>{{textos['Medicalcircumstances']}}</h3>
                                    <textarea
                                        placeholder="En caso de NO haber tenido o tener cirugías, fracturas y/o esguinces que le impidan realizar actividades, teclea la palabra NINGUNA"
                                        name="" id="" cols="90" rows="10"
                                        formControlName = "prevent_activities"
                                        class="form-control"
                                        (input)="getprevent_activities()"
                                         #prevent_activities
                                        ></textarea>
                                       
        
                                </div>
        
                                <div class="container-medico mt-5">
                                    <h3>{{textos['Medicalcondition']}}</h3>
                                    <textarea
                                        [placeholder]="textos['Ifhe/she']"
                                        name="" id="" cols="90" rows="10"
                                        formControlName = "affliction"
                                        class="form-control"
                                        (input)="getaffliction()"
                                         #affliction></textarea>
                                </div>
        
        
        
        
                            </div>
                        </div>
                        <div class="card">
        
                            <div class="card-body">
                                <div class="container-footer-parents">
                                    <h4 class="ms-3">{{textos['Medicines']}}</h4>
                                </div>
        
                                <p style="margin-top: 20px; color:#f46a6a;" class="fw-bold">
                                    Tenemos la política de comunicarnos con los papás antes de administrar cualquier medicamento
                                    que
                                    no haya sido autorizado previamente o enviado por la familia.
                                </p>
        
        
                                <p style="margin-top: 20px; color: #f46a6a                    ;" class="fw-bold">
                                    Para evitarte la molestia de nuestra llamada en casos menores, te proponemos que
                                    pre-autorices
                                    los medicamentos que creas convenientes de la siguiente lista:
                                </p>
        
        
                                <h3 style="margin-top: 40px;">{{textos['Medicamentospreautorizados']}}
                                </h3>
        
                                <div action="" class="mt-5">
                                    <div class="contenedor-datos" *ngFor="let item of licensed_medicines; index as i" >
        
        
                                        <div class="titulos-datos-medicos">
                                            <p  >{{item.name}}</p>
                                        </div>
                                        <div class="input-datos-medicos" style="margin-top: 0px;">
        
                                            <div >
                                                <input type="checkbox" style="margin-top:13px ;" (click)="licensed_medicinesValu(i)" value="{{item.is_active}}" 
                                                    id="{{item.id}}">
                                                <br>
                                            </div>
                                        </div>
        
                                    </div>
        
                                    <div class="alert alert-secondary">
                                        <p>Descuida que al final del campamento, te notificaríamos si tu hij@ tuvo algún
                                            malestar y
                                            qué medicamento se le administró.</p>
        
                                        <p style="text-align:justify ;">Te pedimos que selecciones los medicamentos que
                                            autorizas.
                                            Cualquier medicamento no
                                            seleccionado se considerará como NO PRE-AUTORIZADO, en cuyo caso, nos comunicaremos
                                            contigo.</p>
                                    </div>
        
                                    <div class="container-medico mt-5" placement="top"
                                        ngbTooltip="Si no requiere de tratamiento médico, teclea la palabra NINGUNO">
                                        <h3>{{textos['Duringcamp']}}</h3>
                                        <textarea
                                            placeholder="Especificar dosis y horarios para la toma de medicamentos en tal caso de no necitar poner NINGUNO"
                                            name="" id="" cols="90" rows="10"
                                            formControlName = "drugs"
                                            class="form-control"
                                            (input)="getdrugs()"
                                             #drugs></textarea>
                                            <div class="alert alert-danger mt-2" role="alert" *ngIf="drugs.invalid">
                                                                                         Campo requerido
                                              </div>
        
                                    </div>
        
                                    <div class="container-medico mt-5" placement="top"
                                        ngbTooltip="Si no requiere de tratamiento médico, teclea la palabra NINGUNO">
                                        <h3>{{textos['Drugallergies']}}</h3>
                                        <textarea
                                            placeholder="En caso de que el acampador no sea alérgico a algún medicamento, teclea la palabra NINGUNO"
                                            name="" id="" cols="90" rows="10"
                                            formControlName = "drug_allergies"
                                            class="form-control"
                                            (input)="getdrug_allergies()"
                                             #drug_allergies></textarea>
                                            
        
                                    </div>
                                    <div class="container-medico mt-5" placement="top"
                                        ngbTooltip="Si no requiere de tratamiento médico, teclea la palabra NINGUNO">
                                        <h3><h3>{{textos['OtrasCondiciones']}}</h3></h3>
                                        <textarea
                                            placeholder="En caso de que el acampador no sea alérgico, teclea la palabra NINGUNA"
                                            name="" id="" cols="90" rows="10"
                                            formControlName = "other_allergies"
                                            class="form-control"
                                            (input)="getother_allergies()"
                                             #other_allergies></textarea>
                                            <div class="alert alert-danger mt-2" role="alert" *ngIf="other_allergies.invalid">
                                                                                         Campo requerido
                                              </div>
        
        
                                    </div>
                                    <div class="container-fluid flex-container">
                                       
                                        <div class="form-check">
                                            <input type="checkbox" class=" form-check-input" formControlName="doctor_precall">
                                            <label for="" class="form-check-label"> Me gustaría tener una llamada con el doctor antes de que inicie el campamento, pues hay detalles importantes que quiero compartir y que podrían tomar demasiado tiempo si lo hago el día que sale el campamento</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
        
        
        
                        </div>
                       
        
                        <div class="card">
        
                            <div class="card-body">
                                <div class="container-footer-parents">
                                    <h4 class="ms-3">{{textos['OtrasCondicionest']}}
                                    </h4>
                                </div>
        
        
                                <div class="mt-5">
        
        
                                    <div class="container-medico mt-5">
                                        <h3>{{textos['Nightdisturbance']}}</h3>
                                        <textarea
                                            placeholder="En caso de que el acampador no padezca de alteraciones nocturnas, teclea la palabra NINGUNA"
                                            name="" id="" cols="90" rows="10"
                                            formControlName="nocturnal_disorders"
                                            class="form-control"
                                            (input)="getnocturnal_disorders()"
                                             #nocturnal_disorders></textarea>
                                         
        
                                    </div>
                                    <div class="container-medico mt-5">
                                        <h3>{{textos['Phobiasfears']}}</h3>
                                        <textarea
                                            placeholder="En caso de que el acampador no padezca de fobias, teclea la palabra NINGUNO"
                                            name="" id="" cols="90" rows="10"
                                            formControlName="phobias"
                                            class="form-control"
                                            (input)="getphobias()"
                                             #phobias></textarea>
                                           
        
                                    </div>
        
                                    <div class="container-medico mt-5">
                                        <h3>{{textos['Psychiatricpsychologicaltreatments']}}</h3>
                                        <textarea
                                            placeholder="En caso de NO estar en ningun tratamiento psicologico o psiquiatrico, teclea la palabra NINGUNO"
                                            name="" id="" cols="90" rows="10"
                                            formControlName="psicology_treatments"
                                            class="form-control"
                                            (input)="getpsicology_treatments()"
                                             #psicology_treatments></textarea>
                                           
        
                                    </div>
        
                                    <label for="" class="mt-5 col-sm-6 col-form-label">{{textos['Doeshe/she']}}</label>
                                    <div class="container-genero">
                                        <div class="mb-3">
        
        
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="can_swim"
                                                    id="inlineRadio1" [value]=86 formControlName="can_swim">
                                                <label class="form-check-label" for="inlineRadio1">Si</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="can_swim"
                                                    id="inlineRadio2" [value]=87 formControlName="can_swim">
                                                <label class="form-check-label" for="inlineRadio2">No</label>
                                            </div>
        
                                        </div>
                                   
                                    </div>
        
                                    <div class="alert alert-warning">
                                        En caso de no saber nadar y querer participar en actividades acuáticas, será
                                        responsabilidad
                                        de los padres de familia el incluir en su equipaje un dispositivo de flotación (chaleco,
                                        flotis)
                                    </div>
        
        
        
        
                                </div>
                            </div>
        
                        </div>
                        <div class="card">
        
                            <div class="card-body">
                                <div class="container-footer-parents">
                                    <img src="assets/images/camp/chef.png" class="avatar-lg" alt="madre y padres">
                                    <h4 class="ms-3">{{textos['Food']}}
                                    </h4>
                                </div>
        
        
                                <div class="mt-5">
                                    <label for="" class="col-sm-6 col-form-label">
                                        {{textos['Food']}}</label>
                                    <div class="container-genero">
                                        <div class="mb-3">
        
        
                                            <div class="form-check form-check-inline" *ngFor="let item of food_restrictions;index as i">
                                                <input (click)="food_restrictionsValu(i)" class="form-check-input" type="checkbox" name="inlineRadioOptions" [checked]="item.is_active"
                                                    id="inlineRadio1" value="{{item.is_active}}" id="{{item.id}}"
                                                    >
                                                <label class="form-check-label" for="inlineRadio1">{{item.name}}</label>
                                            </div>
        
        
                                        </div>
                                    </div>
        
        
                                    <div class="container-medico mt-5">
                                        <h3>{{textos['Forbiddenfood']}}</h3>
                                        <textarea
                                            placeholder="En caso de que el acampador pueda comer cualquier tipo de comida, teclea la palabra NINGUNA"
                                            name="" id="" cols="90" rows="10"
                                            formControlName ="prohibited_foods"
                                            class="form-control"
                                            (input)="getprohibited_foods()"
                                             #prohibited_foods></textarea>
        
                                            <div class="alert alert-danger mt-2" role="alert" *ngIf="prohibited_foods.invalid">
                                                                                         Campo requerido
                                              </div>
        
                                    </div>
        
                                </div>
                            </div>
        
                        </div>
        
                        <div class="card">
        
                            <div class="card-body">
                                <div class="container-footer-parents">
                                    <h4 class="ms-3"> Contacto de emergencia
                                    </h4>
                                </div>
        
                                <div class="alert alert-success mt-3">
                                    En caso de emergencia y NO PODER CONTACTAR A LOS PADRES, registra los datos de una tercera
                                    persona.
                                </div>
        
        
                                <div class="mt-5">
                                    <div class="row mb-4">
                                        <label for="" class="col-sm-3 col-form-label"> {{textos['ContactName']}}
                                        </label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" id=""
                                            formControlName = "contact_name"  #contact_name (input)="getcontact_name()"
                                            >
                                        </div>
                                    </div>
        
                                    <div class="row mb-4">
                                        <label for="" class="col-sm-3 col-form-label">  {{textos['Relationcamper']}} </label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" id=""
                                            formControlName = "contact_relation" #contact_relation (input)="getcontact_relation()"
                                            >
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <label for="" class="col-sm-3 col-form-label"> {{textos['Mobilephone']}} </label>
                                        <div class="col-sm-9">
                                            <input type="number" class="form-control" id=""
                                            formControlName = "contact_cellphone"  #contact_cellphone (input)="getcontact_cellphone()"
                                            >
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <label for="" class="col-sm-3 col-form-label"> {{textos['Homephone']}} </label>
                                        <div class="col-sm-9">
                                            <input type="number" class="form-control" id="" formControlName ="contact_homephone"
                                            #contact_homephone (input)="getcontact_homephone()"
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
        
        
        
                        </div>
        
        
                        <div class="card">
        
                            <div class="card-body">
                                <div class="container-footer-parents">
                                    <h4 class="ms-3"> Seguro Médico
                                    </h4>
                                </div>
                                <div class="mt-5">
                                    <div class="container-fluid flex-container">
                                        <div class="form-check">
                                            <input type="checkbox" class="form-check-input" formControlName="insurance">
                                            <label for="" class="form-check-label"> {{textos['Doyouhave']}} </label>
                                        </div>
                                    </div>
                                    <div class="row mb-4 mt-3">
                                        <label for="" class="col-sm-3 col-form-label"> {{textos['InsuranceCompany']}}
                                        </label>
                                        <div class="col-sm-9">
        
                                        <input type="text" class="form-control" id="" formControlName="insurance_company"  #insurance_company (input)="getinsurance_company()">
                                          
                                        </div>
                                    </div>
        
                                    <div class="row mb-4">
                                        <label for="" class="col-sm-3 col-form-label"> {{textos['policynumber']}}</label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" id="" formControlName = "insurance_number"
                                            (input)="getinsurance_number()" #insurance_number
                                            >
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <label for="" class="col-sm-3 col-form-label">{{textos['SocialSecurity']}} </label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" id="" formControlName="security_social_number" #security_social_number
                                            (input)="getsecurity_social_number()"
                                            >
                                        </div>
                                    </div>
                                    
        
                                </div>
                            </div>
        
                        </div>
                    </div>
                  
                </div>
        
                <div class="col-12">
                    <div class=col-lg-12>
                        <div class="card p-5">
                           
                        
        
                            <div style="display: flex; align-items:center ;align-items: center; flex-direction: column;" >
                               
                                    <button class="btn btn-info mx-auto" style="width: 90%;"  (click)="prueba1()" *ngIf="!spinner" >Guardar</button>
                                    <button class="btn btn-secondary mx-auto   mt-3" style="width: 90%;" (click)="canelar()">Cancelar</button>

                                    <div class="spinner-border" role="status" *ngIf="spinner">
                                        <span class="visually-hidden">Loading...</span>
                                      </div>
                                
                              
                            </div>
                        </div>
                    </div>
                </div>
                <p-dialog header="Buscador" *ngIf="displayEdit" [(visible)]="displayEdit" position="right" modal="True" [style]="{width: '50vw'}">
                    <div class="row mb-4">
                        <label for=""
                            class="col-sm-3 col-form-label">Buscar Padre o tutor</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control " id="" formControlName="parent_name" name="parent_name"  (input)="searchparten()">
                                <div class="alert alert-warning" role="alert" *ngIf="!resSearch">        
                                    Por favor Escriba los 3 primeros caracteres del nombre del padre o tutor para inciar la busqueda
                                 </div>
                            </div>
                    </div>
                    <div class="alert alert-primary" role="alert" *ngIf="resSearch">
                       
                                Se encontro un total de {{parent.length}}
                 
                        
                      </div>
                      
                    <div class="row mb-4" >
                        <label for=""
                            class="col-sm-3 col-form-label">Padre o tutor</label>
                            <div class="col-sm-9">
                                <select bindLabel="value" bindValue="id" formControlName="parent_id"  style="width: 100%;"   class=" form-control" >
                                    <option *ngIf="!resSearch">Por favor Agregue un nombre en el buscador para empezar</option>
                                    <option *ngIf="resSearch">Seleciona un Padre o Tutor</option>
                                    <option *ngFor="let items of parent" [value]="items.tutor_id" > {{items.tutor_name}}  {{items.tutor_lastname_father}}  {{items.tutor_lastname_mother}}</option>
                                </select>
                            </div>
                    </div>
                    <p-dialog-footer>
                        <button class="btn btn-primary" (click)="select()" *ngIf="!spinner">Guardar</button>
                        <div class="spinner-border" role="status" *ngIf="spinner">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                    </p-dialog-footer>	
                </p-dialog>
            </div>
          
    
        </form>
       
        <div class="alert alert-primary" role="alert" *ngIf="statuAgrgado">
            Se Agrego correctamente
          </div>

          
    </div>
    
</div>
<div   *ngIf="display2">
    <ng-template pTemplate="header">
       <h2>
        Editar  Camper
       </h2>
     
      
    </ng-template>
    <form [formGroup]="formFood">
        <div class="row">

            <div class="col-12">
                <div class="card">

                    <div class="card-body">
                        <div class="container-footer-parents">
                            <h4 class="ms-3">{{textos['CampersPersonalInformation']}}</h4>
                        </div>
                        <div class="mt-5">
                            <div class="row mb-4">
                                <label for=""
                                    class="col-sm-3 col-form-label">{{textos['Name']}}</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control " id="" formControlName="name" name="name" #name (input)="getname()">
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label for=""
                                    class="col-sm-3 col-form-label">Buscar Padre o tutor</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control " id=""    [value]="nameParent" disabled="false" (input)="searchparten()">
                                        <button type="button" class="btn btn-primary" (click)="showDialogSearchUp()" icon="pi pi-info-circle" label="Selecionar  Padre o Tutor">Selecionar  Padre o Tutor</button>
                                    </div>

                            </div>
                            <div class="row mb-4">
                                <label for="" class="col-sm-3 col-form-label">{{textos['lastname']}}</label>
                                   
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id=""
                                        formControlName="lastname_father" name="lastname_father" (input)="getlastname_father()" #lastname_father>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label for="" class="col-sm-3 col-form-label">{{textos['motherslastname']}}</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id=""
                                        formControlName="lastname_mother"  name="lastname_mother" #lastname_mother (input)="getlastname_mother()">
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label for="" class="col-sm-3 col-form-label">{{textos['email']}}</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id=""
                                        formControlName="email"  name="email" #email (input)="getemail()">
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label for="" class="col-sm-3 col-form-label">{{textos['Gender']}}</label>

                                <div class="col-sm-9">
                                    <select bindLabel="value" bindValue="id" formControlName="gender_id"  style="width: 100%;" (change)="getgender_id()"  class=" form-control" #gender_id>
                                        <option *ngFor="let item of genders" [value]="item.id" > {{item.value}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="row mb-4 mt-3">
                                <label for="" class="col-sm-3 col-form-label">{{textos['Birthdate']}}</label>
                                <div class=" control-label col-sm-9">
                                    
                                        <input type="date" class="form-control" placeholder="yyyy-mm-dd"
                                        formControlName="birthday" style="width: 100%;"  (input)="getbirthday()" #birthday>
                                   
                                </div>
                            </div>
                            <div class="foto mt-4">
                                <div class="container-footer-parents">
                                    <img [src]="photoSelectUp || 'assets/images/camp/perfil-camper.png'" class="avatar-lg" alt="madre y padres">
                                    
                                </div>

                                <div class="mt-3 foto"  tabindex="0" id="photo">
                                    <label for="">{{textos['Profilepicture']}}</label>
                                    <input type="file" accept="image/jpeg" (change)="subiendo($event)" >
                                    
    
                                    <label class="mt-2">La foto es obligatoria ,nos ayuda a identificar a los campers,
                                        recomendamos subas una foto reciente y de poco peso, máximo 3Mb debe ser formato jpg</label>
    
                                </div>
                                <div *ngIf="spinerPhot">
                                  
                                </div>
        
                                  <div class="spinner-border" role="status" *ngIf="!spinerPhot">
                                    <span class="visually-hidden">Loading...</span>
                                  </div>
                              
                            </div>
                        </div>
                    </div>

                </div>
                <div class="card">

                    <div class="card-body">
                        <div class="container-footer-parents">
                            <h4 class="ms-3">{{textos['Schoolinformation']}}</h4>
                        </div>
                        <div class="mt-5">
                            <div class="row mb-4">
                                <label for="" class="col-sm-3 col-form-label">{{textos['School']}}</label>
                                <div class="control-label col-sm-9">
                                    <ng-select
                                    name="school_id"
                                    id="school_id"
                                    formControlName="school_id"
                                    [items]="school" 
                                    bindLabel="name"
                                    bindValue="id"
                                    (change)="getschool_id()"
                                    [searchable]="true"
                                    class="form-control">
                                </ng-select>
                                
                                  
                                </div>
                               
                            </div>
                            <div class="row mb-4">
                                <label for="" class="col-sm-3 col-form-label">{{textos['Otherschool']}}</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="" formControlName="school_other" (input)="getSchool_other()" #school_other>
                                </div>
                               
                            </div>
                            <div class="row mb-4" >
                                <label for="" class="col-sm-3 col-form-label">{{textos['Grade']}}</label>
                                <div class="control-label col-sm-9">
                                  

                                    <select name="school_id" id="school_id" formControlName="grade" (change)="getgrade()" class="form-control" #grade>
                                        <option *ngFor="let item of grades" [value]="item.id" >
                                            {{item.value}}
                                        </option>
                                    </select>
                                </div>
                                <div class="alert alert-danger mt-2" role="alert" *ngIf="grade.invalid">
                                                                             Campo requerido
                                  </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    
                <div class="card">

                    <div class="card-body">
                        <div class="container-footer-parents">
                            <h4 class="ms-3">{{textos['MedicalinformationGeneralMedicalData']}}</h4>
                        </div>
                        <div class="mt-5">
                            <div class="row mb-4">
                                <label for="" class="col-sm-3 col-form-label">{{textos['Height']}}</label>
                                

                                <div class="control-label col-sm-9">
                                    <div class="input-group mb-3">
                                        <input class="form-control" type="number"  min="0" max="200" step="0.5" id="customRange3"   suffix="cm" formControlName="height" 
                                        #height
                                        (input)="getheight()"
                                      />
                                        <div class="input-group-append">
                                          <span class="input-group-text" id="basic-addon2">CM</span>
                                        </div>
                                    </div>
                                  
                                  </div>
                            </div>
                            <div class="row mb-4">
                                <label for="" class="col-sm-3 col-form-label">{{textos['Weight']}}</label>
                                <div class="col-sm-9">
                                 
                                         <div class="input-group mb-3">
                                            <input type="number" class="form-control" id=""  step="5" min="0" max="200"
                                            formControlName="weight" #weight
                                            (input)="getweight()"
                                           
                                            >
                                            <div class="input-group-append">
                                              <span class="input-group-text" id="basic-addon2">kg</span>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            
                            <div class="row mb-4">

                                <label for="" class="col-sm-6 col-form-label">
                                    {{textos['Currentvaccines']}}</label>
                                <div class="container-genero">
                                    <div class="mb-3">
    
    
                                        <div class="form-check form-check-inline" *ngFor="let item of vaccines;index as i">
                                            <input (click)="vaccinesValu(i)" class="form-check-input" type="checkbox" name="inlineRadioOptions"
                                                value="{{item.is_active}}" [checked]="item.is_active"
                                                >
                                            <label class="form-check-label" >{{item.name}}</label>
                                        </div>
    
    
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label for="" class="col-sm-3 col-form-label">{{textos['Bloodtype']}}</label>
                                <div class="control-label col-sm-9">
                                    <select name="school_id" id="school_id" formControlName="blood_type" (change)="getblood_type()" class="form-control" #blood_type>
                                        <option *ngFor="let item of blood_types" [value]="item.id" >
                                            {{item.value}}
                                        </option>
                                    </select>
                                   

                                    <small>{{textos['Ifyoudon']}}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>

                   


                </div>
                
                </div>
                <div class="card-body card">
                    <div class="container-footer-parents">
                        <h4 class="ms-3">{{textos['DiseaseRegistry']}}
                        </h4>
                    </div>

                    <h2 style="text-align:center ; margin-top: 30px;">{{textos['Pathologicalhistory']}}</h2>
                    <div class="mt-5">
                        <table class="tabla-medicos" style="width: 100%;">
                            <thead>
                                <tr>
                                    <th style="background-color: white;">Nombre</th>
                                    <th style="background-color: white;">{{ textos['CAMPER'] }}</th>
                                    <th style="background-color: white;">{{ textos['FAMILY'] }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of pathological_background; index as i">
                                    <td class="nombre">{{ item.name }}</td>
                                    <td>
                                        <input (click)="pathological_backgroundValu(i)" type="checkbox" style="margin-top:10px ;" value="{{item.is_active}}" [checked]="item.is_active" 
                                        id="{{item.id}}">
                                    </td>
                                    <td>
                                        <input *ngIf="pathological_background_fm[i]" 
                                               (click)="pathological_background_fmValu(i)" 
                                               type="checkbox" 
                                               [checked]="pathological_background_fm[i]?.is_active" 
                                               id="{{pathological_background_fm[i]?.id}}">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="alert-dismissible alert alert-secondary">
                            <p style="text-align:justify;" for="">Marca las casillas de las enfermedades que haya
                                padecido o padezca el camper en la columna camper. En caso de que un familiar haya
                                padecido alguna de esas enfermedades marca en la columna de familiar</p>
                        </div>


                        <div class="container-medico mt-5">
                            <h3>{{textos['Heartproblems']}}</h3>
                            <textarea
                                placeholder="En caso de NO padecer ningun problema cardiaco, teclea la palabra NINGUNO"
                                name="" id="" cols="90" rows="10"
                                formControlName = "heart_problems"
                                class="form-control"
                                (input)="getheart_problems()"
                                 #heart_problems></textarea>
                        </div>
                        <div class="container-medico mt-5">
                            <h3>{{textos['Medicalcircumstances']}}</h3>
                            <textarea
                                placeholder="En caso de NO haber tenido o tener cirugías, fracturas y/o esguinces que le impidan realizar actividades, teclea la palabra NINGUNA"
                                name="" id="" cols="90" rows="10"
                                formControlName = "prevent_activities"
                                class="form-control"
                                (input)="getprevent_activities()"
                                 #prevent_activities
                                ></textarea>
                               

                        </div>

                        <div class="container-medico mt-5">
                            <h3>{{textos['Medicalcondition']}}</h3>
                            <textarea
                                [placeholder]="textos['Ifhe/she']"
                                name="" id="" cols="90" rows="10"
                                formControlName = "affliction"
                                class="form-control"
                                (input)="getaffliction()"
                                 #affliction></textarea>
                        </div>




                    </div>
                </div>
                <div class="card">

                    <div class="card-body">
                        <div class="container-footer-parents">
                            <h4 class="ms-3">{{textos['Medicines']}}</h4>
                        </div>

                        <p style="margin-top: 20px; color:#f46a6a;" class="fw-bold">
                            Tenemos la política de comunicarnos con los papás antes de administrar cualquier medicamento
                            que
                            no haya sido autorizado previamente o enviado por la familia.
                        </p>


                        <p style="margin-top: 20px; color: #f46a6a                    ;" class="fw-bold">
                            Para evitarte la molestia de nuestra llamada en casos menores, te proponemos que
                            pre-autorices
                            los medicamentos que creas convenientes de la siguiente lista:
                        </p>


                        <h3 style="margin-top: 40px;">{{textos['Medicamentospreautorizados']}}
                        </h3>

                        <div action="" class="mt-5">
                            <div class="contenedor-datos" *ngFor="let item of licensed_medicines; index as i">


                                <div class="titulos-datos-medicos">
                                    <p  >{{item.name}}</p>
                                </div>
                                <div class="input-datos-medicos" style="margin-top: 0px;">

                                    <div  >
                                        <input type="checkbox" style="margin-top:13px ;" (click)="licensed_medicinesValu(i)" value="{{item.is_active}}"  [checked]="item.is_active" 
                                            id="{{item.id}}">
                                        <br>
                                    </div>
                                </div>

                            </div>

                            <div class="alert alert-secondary">
                                <p>Descuida que al final del campamento, te notificaríamos si tu hij@ tuvo algún
                                    malestar y
                                    qué medicamento se le administró.</p>

                                <p style="text-align:justify ;">Te pedimos que selecciones los medicamentos que
                                    autorizas.
                                    Cualquier medicamento no
                                    seleccionado se considerará como NO PRE-AUTORIZADO, en cuyo caso, nos comunicaremos
                                    contigo.</p>
                            </div>

                            <div class="container-medico mt-5" placement="top"
                                ngbTooltip="Si no requiere de tratamiento médico, teclea la palabra NINGUNO">
                                <h3>{{textos['Duringcamp']}}</h3>
                                <textarea
                                    placeholder="Especificar dosis y horarios para la toma de medicamentos en tal caso de no necitar poner NINGUNO"
                                    name="" id="" cols="90" rows="10"
                                    formControlName = "drugs"
                                    class="form-control"
                                    (input)="getdrugs()"
                                     #drugs></textarea>
                                    <div class="alert alert-danger mt-2" role="alert" *ngIf="drugs.invalid">
                                                                                 Campo requerido
                                      </div>

                            </div>

                            <div class="container-medico mt-5" placement="top"
                                ngbTooltip="Si no requiere de tratamiento médico, teclea la palabra NINGUNO">
                                <h3>{{textos['Drugallergies']}}</h3>
                                <textarea
                                    placeholder="En caso de que el acampador no sea alérgico a algún medicamento, teclea la palabra NINGUNO"
                                    name="" id="" cols="90" rows="10"
                                    formControlName = "drug_allergies"
                                    class="form-control"
                                    (input)="getdrug_allergies()"
                                     #drug_allergies></textarea>
                                    

                            </div>
                            <div class="container-medico mt-5" placement="top"
                                ngbTooltip="Si no requiere de tratamiento médico, teclea la palabra NINGUNO">
                                <h3><h3>{{textos['OtrasCondiciones']}}</h3></h3>
                                <textarea
                                    placeholder="En caso de que el acampador no sea alérgico, teclea la palabra NINGUNA"
                                    name="" id="" cols="90" rows="10"
                                    formControlName = "other_allergies"
                                    class="form-control"
                                    (input)="getother_allergies()"
                                     #other_allergies></textarea>
                                    <div class="alert alert-danger mt-2" role="alert" *ngIf="other_allergies.invalid">
                                      </div>


                            </div>
                            <div class="container-fluid flex-container">
                               
                                <div class="form-check">
                                    <input type="checkbox" class=" form-check-input" formControlName="doctor_precall">
                                    <label for="" class="form-check-label"> Me gustaría tener una llamada con el doctor antes de que inicie el campamento, pues hay detalles importantes que quiero compartir y que podrían tomar demasiado tiempo si lo hago el día que sale el campamento</label>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
               

                <div class="card">

                    <div class="card-body">
                        <div class="container-footer-parents">
                            <h4 class="ms-3">{{textos['OtrasCondicionest']}}
                            </h4>
                        </div>


                        <div class="mt-5">


                            <div class="container-medico mt-5">
                                <h3>{{textos['Nightdisturbance']}}</h3>
                                <textarea
                                    placeholder="En caso de que el acampador no padezca de alteraciones nocturnas, teclea la palabra NINGUNA"
                                    name="" id="" cols="90" rows="10"
                                    formControlName="nocturnal_disorders"
                                    class="form-control"
                                    (input)="getnocturnal_disorders()"
                                     #nocturnal_disorders></textarea>
                                 

                            </div>
                            <div class="container-medico mt-5">
                                <h3>{{textos['Phobiasfears']}}</h3>
                                <textarea
                                    placeholder="En caso de que el acampador no padezca de fobias, teclea la palabra NINGUNO"
                                    name="" id="" cols="90" rows="10"
                                    formControlName="phobias"
                                    class="form-control"
                                    (input)="getphobias()"
                                     #phobias></textarea>
                                   

                            </div>

                            <div class="container-medico mt-5">
                                <h3>{{textos['Psychiatricpsychologicaltreatments']}}</h3>
                                <textarea
                                    placeholder="En caso de NO estar en ningun tratamiento psicologico o psiquiatrico, teclea la palabra NINGUNO"
                                    name="" id="" cols="90" rows="10"
                                    formControlName="psicology_treatments"
                                    class="form-control"
                                    (input)="getpsicology_treatments()"
                                     #psicology_treatments></textarea>
                                   

                            </div>

                            <label for="" class="mt-5 col-sm-6 col-form-label">{{textos['Doeshe/she']}}</label>
                            <div class="container-genero">
                                <div class="mb-3">


                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="can_swim"
                                            id="inlineRadio1" [value]=86 formControlName="can_swim">
                                        <label class="form-check-label" for="inlineRadio1">Si</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="can_swim"
                                            id="inlineRadio2" [value]=87 formControlName="can_swim">
                                        <label class="form-check-label" for="inlineRadio2">No</label>
                                    </div>

                                </div>
                           
                            </div>

                            <div class="alert alert-warning">
                                En caso de no saber nadar y querer participar en actividades acuáticas, será
                                responsabilidad
                                de los padres de familia el incluir en su equipaje un dispositivo de flotación (chaleco,
                                flotis)
                            </div>




                        </div>
                    </div>

                </div>
                <div class="card">

                    <div class="card-body">
                        <div class="container-footer-parents">
                            <img src="assets/images/camp/chef.png" class="avatar-lg" alt="madre y padres">
                            <h4 class="ms-3">{{textos['Food']}}
                            </h4>
                        </div>


                        <div class="mt-5">
                            <label for="" class="col-sm-6 col-form-label">
                                {{textos['Food']}}</label>
                            <div class="container-genero">
                                <div class="mb-3">


                                    <div class="form-check form-check-inline" *ngFor="let item of food_restrictions;index as i">
                                        <input (click)="food_restrictionsValu(i)" class="form-check-input" type="checkbox" name="inlineRadioOptions" [checked]="item.is_active"
                                            id="inlineRadio1" value="{{item.is_active}}" id="{{item.id}}"
                                            >
                                        <label class="form-check-label" for="inlineRadio1">{{item.name}}</label>
                                    </div>


                                </div>
                            </div>


                            <div class="container-medico mt-5">
                                <h3>{{textos['Forbiddenfood']}}</h3>
                                <textarea
                                    placeholder="En caso de que el acampador pueda comer cualquier tipo de comida, teclea la palabra NINGUNA"
                                    name="" id="" cols="90" rows="10"
                                    formControlName ="prohibited_foods"
                                    class="form-control"
                                    (input)="getprohibited_foods()"
                                     #prohibited_foods></textarea>

                                    <div class="alert alert-danger mt-2" role="alert" *ngIf="prohibited_foods.invalid">
                                                                                 Campo requerido
                                      </div>

                            </div>

                        </div>
                    </div>

                </div>

                <div class="card">

                    <div class="card-body">
                        <div class="container-footer-parents">
                            <h4 class="ms-3"> Contacto de emergencia
                            </h4>
                        </div>

                        <div class="alert alert-success mt-3">
                            En caso de emergencia y NO PODER CONTACTAR A LOS PADRES, registra los datos de una tercera
                            persona.
                        </div>


                        <div class="mt-5">
                            <div class="row mb-4">
                                <label for="" class="col-sm-3 col-form-label"> {{textos['ContactName']}}
                                </label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id=""
                                    formControlName = "contact_name"  #contact_name (input)="getcontact_name()"
                                    >
                                </div>
                            </div>

                            <div class="row mb-4">
                                <label for="" class="col-sm-3 col-form-label">  {{textos['Relationcamper']}} </label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id=""
                                    formControlName = "contact_relation" #contact_relation (input)="getcontact_relation()"
                                    >
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label for="" class="col-sm-3 col-form-label"> {{textos['Mobilephone']}} </label>
                                <div class="col-sm-9">
                                    <input type="number" class="form-control" id=""
                                    formControlName = "contact_cellphone"  #contact_cellphone (input)="getcontact_cellphone()"
                                    >
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label for="" class="col-sm-3 col-form-label"> {{textos['Homephone']}} </label>
                                <div class="col-sm-9">
                                    <input type="number" class="form-control" id="" formControlName ="contact_homephone"
                                    #contact_homephone (input)="getcontact_homephone()"
                                    >
                                </div>
                            </div>
                        </div>
                    </div>



                </div>


                <div class="card">

                    <div class="card-body">
                        <div class="container-footer-parents">
                            <h4 class="ms-3"> Seguro Médico
                            </h4>
                        </div>
                        <div class="mt-5">
                            <div class="container-fluid flex-container">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" formControlName="insurance">
                                    <label for="" class="form-check-label"> {{textos['Doyouhave']}} </label>
                                </div>
                            </div>
                            <div class="row mb-4 mt-3">
                                <label for="" class="col-sm-3 col-form-label"> {{textos['InsuranceCompany']}}
                                </label>
                                <div class="col-sm-9">

                                <input type="text" class="form-control" id="" formControlName="insurance_company"  #insurance_company (input)="getinsurance_company()">
                                  
                                </div>
                            </div>

                            <div class="row mb-4">
                                <label for="" class="col-sm-3 col-form-label"> {{textos['policynumber']}}</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="" formControlName = "insurance_number"
                                    (input)="getinsurance_number()" #insurance_number
                                    >
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label for="" class="col-sm-3 col-form-label">{{textos['SocialSecurity']}} </label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="" formControlName="security_social_number" #security_social_number
                                    (input)="getsecurity_social_number()"
                                    >
                                </div>
                            </div>
                            

                        </div>
                    </div>

                </div>
            </div>
            <div class="col-12">
                <div class=col-lg-12>
                    <div class="card p-5">
                       
                    
    
                        <div style="display: flex; align-items: center;flex-direction: column; align-items: center;" >
                           
                                <button class="btn btn-info  mb-2"  style="width: 90%;" (click)="keepUpdate()"   *ngIf="!spinner" >Guardar</button>
                                <button class="btn btn-secondary " style="width: 90%;" (click)="cancelarUpdate()"  
                                style="width: 90%;">Cancelar</button>
                                <div class="spinner-border" role="status" *ngIf="spinner">
                                    <span class="visually-hidden">Loading...</span>
                                  </div>
                                
                         
                          
                        </div>
                    </div>
                </div>
            </div>
            <p-dialog header="Buscador" *ngIf="displayEditUpd" [(visible)]="displayEditUpd" position="right" modal="True" [style]="{width: '50vw'}">
                <div class="row mb-4">
                    <label for=""
                        class="col-sm-3 col-form-label">Buscar Padre o tutor</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control " id="" formControlName="parent_name" name="parent_name"  (input)="searchpartenEdit()">
                            <div class="alert alert-warning" role="alert" *ngIf="!resSearch">        
                                Por favor Escriba los 3 primeros caracteres del nombre del padre o tutor para inciar la busqueda
                             </div>
                        </div>
                </div>
                <div class="alert alert-primary" role="alert" *ngIf="resSearch">
                   
                            Se encontro un total de {{parent.length}}
             
                    
                  </div>
                  
                <div class="row mb-4" >
                    <label for=""
                        class="col-sm-3 col-form-label">Padre o tutor</label>
                        <div class="col-sm-9">
                            <select bindLabel="value" bindValue="id" formControlName="parent_id"  style="width: 100%;"   class=" form-control" >
                                <option *ngIf="!resSearch">Por favor Agregue un nombre en el buscador para empezar</option>
                                <option *ngIf="resSearch">Seleciona un Padre o Tutor</option>
                                <option *ngFor="let items of parent" [value]="items.tutor_id" > {{items.tutor_name}}  {{items.tutor_lastname_father}}  {{items.tutor_lastname_mother}}</option>
                            </select>
                        </div>
                </div>
                <p-dialog-footer>
                    <button class="btn btn-primary" (click)="select()">Guardar</button>
                </p-dialog-footer>	
            </p-dialog>
          
        </div>

       

    </form>
   
   

    <ng-template pTemplate="footer">
        <button type="button" class="btn btn-outline-secondary" (click)="closeModal2()">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="keepUpdate()" *ngIf="!spinner">Guardar</button>
        <div class="spinner-border" role="status" *ngIf="spinner">
            <span class="visually-hidden">Loading...</span>
          </div>
    </ng-template>
</div>

<p-dialog header="Title" [(visible)]="display3" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}">
    <ng-template pTemplate="header">
       <h2>
        Eliminar  Camper
       </h2> 
    </ng-template>
    <div class="col">
       {{TextElimint}}
        <div class="alert alert-danger" role="alert" *ngIf="statuAgrgado">
            Se elimino correctamente
          </div>

          
    </div>
    <ng-template pTemplate="footer">
        <button type="button" class="btn btn-outline-secondary" (click)="this.display3 = false">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="delet()">Eliminar</button>
    </ng-template>
</p-dialog>
<p-tabView *ngIf="table">
    <p-tabPanel header="Acampadores">
        <div>

            <div class="card border border-info border-1">
                <div class="bg-info p-2">
                    <h1 class="card-title text-white">Acampadores</h1>
                </div>
                <div class="card-body">
                <div class="container-btn mt-1 mb-3">
                    <div class="btn-footer">
                        <div ngbDropdown placement="top-end" class="d-inline-block" style="margin-right: 10px;">
                            <button type="button" class="btn btn-primary" (click)="showDialog()">Agregar nuevo Acampador</button>
        
                        </div>

                        <div ngbDropdown placement="top-end" class="d-inline-block" style="margin-right: 10px;">
                            <button type="button" class="btn btn-primary" (click)="buscador=!buscador">Buscador</button>
        
                        </div>
                    </div>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="Global Search" />
                </div>
        <div style="overflow: auto;" *ngIf="spiner">
            <p-table #dt [value]="listcatalogos" [rows]="20"
            [rowsPerPageOptions]="[5, 25, 50]"
            [globalFilterFields]="['camper_name','camper_lastname_mother','camper_lastname_father','school','tutor_fullname','tutor_email','camper_id','namecomplet',
            'namecomplet1','namecomplet2','namecomplet3','namecomplet4','namecomplet5']"
            [(selection)]="selectCatalogos" [rowHover]="true" dataKey="id"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [showCurrentPageReport]="true"
            [filterFunction]="customFilter"
            [paginator]="true">

            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="camper_name" style="min-width: 120px;">Nombre<p-sortIcon field="camper_name"style="font-size: 10px;"></p-sortIcon></th>
                    <th pSortableColumn="school" style="min-width: 150px;">Escuela<p-sortIcon field="school"></p-sortIcon></th>
                    <th pSortableColumn="updated" style="min-width: 140px;">Update<p-sortIcon field="updated_at"></p-sortIcon></th>
                    <th pSortableColumn="tutor_fullname" style="min-width: 130px;">Nombre contacto<p-sortIcon field="tutor_fullname"></p-sortIcon></th>
                    <th pSortableColumn="tutor_email" style="min-width: 210px;">correo del parde<p-sortIcon field="tutor_email"></p-sortIcon></th>


                    <th style="width: 40px;"></th>
                    <th style="width: 40px;"></th>
                 
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customer>
                <tr>
                    <td (click)="routerLink(customer)" style="cursor:pointer;">
                       <a routerLink="/dashboard/parents/camper/{{customer.camper_id}}"> {{customer.namecomplet}} </a> 
                    </td>
                 
                    <td>
                        {{customer.school}}
                    </td>
                    <td>
                        {{customer.updated.substring(0,10)}}
                    </td>
                    <td>
                       <a routerLink="/dashboard/admi/parent/{{customer.tutor_id}}">{{customer.tutor_fullname}}</a> 
                    </td>
                    <td>
                         <a routerLink="/dashboard/admi/parent/{{customer.tutor_id}}"> {{customer.tutor_email}} </a> 
                    </td>
                    <td>
                        <button class="btn btn-warning" (click)="update(customer)">Editar</button>
                    </td>
                    <td>
                        <button class="btn btn-danger" (click)="deletModal(customer.camper_name,customer.camper_id)">Eliminar</button>
                    </td>
                 
                </tr>
            </ng-template>

        </p-table>
        </div>
        <div *ngIf="!spiner" class="p-4">
            <div class="d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
            </div>
            </div>
        
        
        
        
        
        </div>
        
    </p-tabPanel>
    
  
</p-tabView>





<p-dialog header="Buscador" [(visible)]="buscador" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '90vw'}">
  
    <div class="container">
        <div class="col-10 mx-auto">
            <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="Ingresa el nombre o correo del padre" aria-label="Ingresa tu búsqueda" aria-describedby="button-addon2" [(ngModel)]="search" >
                <div class="input-group-append">
                  <button class="btn btn-primary" type="button" id="button-addon2" (click)="searchUser()">Buscar</button>
                </div>
              </div>
            <p-table #dt [value]="listBuscador" [rows]="5"
            [rowsPerPageOptions]="[5, 25, 50]"
            [globalFilterFields]="['camper_name','camper_lastname_mother','camper_lastname_father']"
            [(selection)]="selectCatalogos" [rowHover]="true" dataKey="id"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [showCurrentPageReport]="true"
            [paginator]="true">
        
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="camper_name" style="min-width: 120px;">Nombre<p-sortIcon field="camper_name"style="font-size: 10px;"></p-sortIcon></th>

                    <th pSortableColumn="school" style="min-width: 150px;">Escuela<p-sortIcon field="school"></p-sortIcon></th>
                    <th pSortableColumn="updated" style="min-width: 140px;">Update<p-sortIcon field="updated_at"></p-sortIcon></th>
                    <th pSortableColumn="tutor_fullname" style="min-width: 130px;">Nombre contacto<p-sortIcon field="tutor_fullname"></p-sortIcon></th>
                    <th pSortableColumn="tutor_email" style="min-width: 210px;">correo del parde<p-sortIcon field="tutor_email"></p-sortIcon></th>
        
        
        
                 
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customer>
                <tr>
                    <td (click)="updateSeacrh(customer)" style="cursor:pointer;">
                        {{customer.camper_name}} {{customer.camper_lastname_father}}     {{customer.camper_lastname_mother}}
                    </td>
                  
                    <td>
                        {{customer.school}}
                    </td>
                    <td>
                        {{customer.updated.substring(0,10)}}
                    </td>
                    <td>
                        {{customer.tutor_fullname}}
                    </td>
                    <td>
                        {{customer.tutor_email}}
                    </td>
                  
                 
                </tr>
            </ng-template>
        
        </p-table>
        </div>
    </div>
   
</p-dialog>





