
<div class="card  p-4">
    <div class="card-container" [class.expanded]="showAllCards" *ngIf="!loading">

        <div class="card-item green-background">
            <div class="card-content">
                <h2 class="card-amount">{{ contatorTotal | number:'1.1-1' }}</h2>
                <p class="card-title">Pagos recibidos</p>
                <p class="card-transactions">
                  {{ transaciones }} Transacciones
                  <i class="pi pi-check"></i>
                </p>
                <div class="card-icon">
                  <i></i>
                </div>
              </div>
        </div>

        <div class="card-item orange-background">
          <div class="card-content">
              <h2 class="card-amount">{{ camper.length | number:'1.1-1' }}</h2>
              <p class="card-title">Registros activos </p>
              <p class="card-transactions">
            
              </p>
              <div class="card-icon">
                <i></i>
              </div>
            </div>
      </div>
      <!-- Tarjetas -->
      <div *ngFor="let card of cards" class="card-item" 
           [ngClass]="{'blue-background': card.payment_method !== 'Descuentos', 'orange-background': card.payment_method === 'Descuentos'}">
        <div class="card-content">
          <h2 class="card-amount">{{ card.total_amount | number:'1.1-1' }}</h2>
          <p class="card-title">{{ card.payment_method }}</p>
          <p class="card-transactions">
            {{ card.transactions }} Transacciones
            <i class="pi pi-check"></i>
          </p>
          <div class="card-icon">
            <i [ngClass]="card.iconClass"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="spinner-border mx-auto" role="status" *ngIf="loading">

      </div>

    <!-- Botón "Ver más" / "Ver menos" -->
    <button (click)="showAllCards = !showAllCards" class="btn btn-primary mt-3">
      {{ showAllCards ? 'Ver menos' : 'Ver más' }}
    </button>
  </div>
  
  
  <div class="mt-4  border border-success border-3">
    <div class=" scroll-y">
        <div class="bg-success p-2">
            <h1 class="card-title text-white">Campers</h1>

        </div>
        <button class="btn btn-primary m-3 " (click)="open()">
            Aplicar pago masivo
        </button>
            <!-- Icono grande como marca de agua 
  
--> 
<p-table [value]="camper" [paginator]="true" [rows]="10" [globalFilterFields]="['name']" [(selection)]="selectedCampers" *ngIf="!loading">
  <ng-template pTemplate="header">
    <tr>
      <th>
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th pSortableColumn="payments_info.camper_fullname">Camper
        <p-sortIcon field="payments_info.camper_fullname"></p-sortIcon>
      </th>

      <th pSortableColumn="payments_info.camp_statusenrolment_status">Status
        <p-sortIcon field="payments_info.camp_statusenrolment_status"></p-sortIcon>
      </th>
      <th *ngFor="let key of getObjectKeys(tiposPago)" [pSortableColumn]="getPaymentMethodField(key,tiposPago)">
        {{ tiposPago[key].name }}
        <p-sortIcon [field]="getPaymentMethodField(key,tiposPago)"></p-sortIcon>
      </th>
      
      <th pSortableColumn="payments_info.total_payments.amount">Total de pagos
        <p-sortIcon field="payments_info.total_payments.amount"></p-sortIcon>
      </th>
      <th pSortableColumn="payments_info.discounts.amount">Descuentos
        <p-sortIcon field="payments_info.discounts.amount"></p-sortIcon>
      </th>
      <th pSortableColumn="payments_info.refunds.refunds">Rembolso
        <p-sortIcon field="payments_info.refunds.refunds"></p-sortIcon>
      </th>
      <th pSortableColumn="payments_info.camp_status.balance">Saldo
        <p-sortIcon field="payments_info.camp_status.balance"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  
  <ng-template pTemplate="body" let-rowData>
    <tr>
      <td>
        <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
      </td>
      <td>
        <a [routerLink]="['/dashboard/payments', rowData.camper_id, camp]">{{ rowData.camper_fullname }}</a>
        <i *ngIf="rowData.payments_info.camp_statusenrolment_status == 'Inscrito'" class="pi pi-times-circle ms-2" style="color:red"></i>
        <i *ngIf="rowData.number_of_comments > 0" class="fas fa-sticky-note" style="color: rgb(192, 192, 187);"></i>
      </td>

      <td>
        {{ rowData.payments_info.camp_status.enrolment_status }}

      </td>
      <td *ngFor="let key of getObjectKeys(rowData.payments_info.payments_by_payment_method)">
        {{ rowData.payments_info?.payments_by_payment_method[key]?.total_amount || 0 | number:'1.0-0' }}
      </td>
      <td>
        {{ rowData.payments_info.total_payments.amount || 0 | number:'1.0-0' }}
        <p-tag [value]="rowData.payments_info.total_payments.number_of_payments" severity="success" *ngIf="rowData.payments_info.total_payments.number_of_payments > 0"></p-tag>
      </td>
      <td>
        {{ rowData.payments_info.discounts.amount || 0 | number:'1.0-0' }}
        <p-tag [value]="rowData.payments_info.discounts.number_of_discounts" severity="success" *ngIf="rowData.payments_info.discounts.number_of_discounts > 0"></p-tag>
      </td>
      <td>{{ rowData.payments_info.refunds.refunds || 0 | number:'1.0-0' }}</td>
      <td class="text-center">
        {{ rowData.payments_info.camp_status.balance || 0 | number:'1.0-0' }}
        <br>
        <p-tag value="Pagado" severity="success" *ngIf="rowData.payments_info.camp_status.balance == 0"></p-tag>
        <p-tag value="Saldo pendiente" severity="warning" *ngIf="rowData.payments_info.camp_status.balance > 0"></p-tag>
      </td>
    </tr>
  </ng-template>
</p-table>

  <div class="spinner-border mx-auto" role="status" *ngIf="loading">

  </div>
  </div>


  <ng-template #content let-c="close" let-d="dismiss">
	<div class="modal-header">
		
		<button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
	</div>
    <div class="col-12 p-5" [formGroup]="updatePage">
        <div class="row mb-3" >
            <label for="inputEmail3" class="col-sm-3 col-form-label">Método de pago*</label>
            <div class="col-sm-8">
                <select 
                formControlName="payment_method_id"
                name="payment_method_id"
                style="width: 100%;" 
                class=" form-control">
                <option  value="0">Método de pago*</option>
                 <option *ngFor="let item of tiposPago" [value]="item.id">{{item.name}}</option>

                </select>
            </div>
        </div>
        <div class="row mb-3">
            <label for="inputPassword3" class="col-sm-3 col-form-label">Monto*</label>
            <div class="col-sm-8">
                <input type="number" class="form-control" id="inputPassword3" formControlName="payment_amount" name="payment_amount" >
            </div>
        </div>
        <div class="row mb-3">
            <label for="inputEmail3" class="col-sm-3 col-form-label">Tipo de Movimiento*</label>
            <div class="col-sm-8">
                <select 
                formControlName="txn_type_id"
                name="txn_type_id"
                style="width: 100%;" 
                 class=" form-control">
                 <option  value="0">Seleciona un Tipo de Movimiento</option>

                 <option *ngFor="let item of tiposMovimiento" [value]="item.id">{{item.name}}</option>

                </select>
            </div>
        </div>
        <div class="row mb-3">
            <label for="inputPassword3" class="col-sm-3 col-form-label">Número de transacción*</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" formControlName="txn_number"  name="txn_number" >
            </div>
        </div>

        <div class="row mb-3">
            <label for="inputPassword3" class="col-sm-3 col-form-label">Fecha*</label>
            <div class="col-sm-8">
                <input type="date" class="form-control" formControlName="payment_date" name="payment_date" >
            </div>
        </div>

       
        <hr>

        <div class="d-grid gap-2 col-6 mx-auto">
            <button class="btn btn-primary" type="button" (click)="update()" *ngIf="!loading">Aplicar Pago</button>
            <div class="spinner-border mx-auto" role="status" *ngIf="loading">

            </div>
          </div>


          
    </div>
	
</ng-template>