import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Options } from 'ng5-slider';
import { CamperService } from 'src/services/camper.service';
import {AbstractControl, FormBuilder, FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import traducciones  from 'src/assets/json/lengua.json';
import { LangService } from 'src/services/lang.service';
import { NgSelectComponent } from '@ng-select/ng-select';





@Component({
  selector: 'app-camper-nuevo',
  templateUrl: './camper-nuevo.component.html',
  styleUrls: ['./camper-nuevo.component.scss']
})
export class CamperNuevoComponent implements OnInit {
  visibleBarOptions: Options = {
    floor: 0,
    ceil: 180,
    showSelectionBar: true
  };
  visibleSelection=0;
  statusImg = false;
  blood_types:any = [];
  food_restrictions:any = [];
  genders:any = [];
  grades:any = [];
  licensed_medicines:any = [];
  pathological_background:any = [];
  pathological_background_fm:any = [];
  school:any = [];
  vaccines:any = [];
  foto:any;
  spinner:boolean= false;
  photoSelect : string | ArrayBuffer;
  estatura ='';
  placeholder={
    "eng":{
      place1:"If you do NOT have any heart problem, type the word “NONE”",
      place2:"If the camper has NEVER had a surgery, fracture and/or sprain that would prevent him/her from participating in our activities, type the word “NONE”",
      place3:"If no treatment is required, type NONE.",
      place4:"If he/she hasn't any drug allergies, type NONE",
      place5:"If he/she hasn't any allergies, type NONE",
      place6:"If he/she hasn't any night disturbance, type NONE",
      place7:"If he/she hasn't any phobias or fears, type NONE",
      place8:"If the camper has NO treatments, type the Word “NONE",
      place9:"If he/she can eat any kind of food, type NONE",
      leyenda1:"Camper Control has accident expense insurance with PANAMERICAN. If medical expenses exceed the insured amount, the costs will be covered by the parents or by the camper's major medical insurance (if applicable). Check your camp details to find out the insured amounts and characteristics of the insurance.",
      leyenda2:"Swimming does not refer to specific sports skills but to the ability to float and move in the water by making the necessary movements with the body and limbs without touching the bottom or using any object for support.  If the camper does not know how to swim and wants to participate in water activities, it will be the responsibility of the parents to include a flotation device (life jacket, floatie) in their luggage.",
      leyenda3:"We ask you to select the medications you authorize. Any medication not selected will be considered NOT PRE-AUTHORIZED, in which case we will contact you. We have a policy of contacting parents before administering any medication that has not been previously authorized or sent by the family.",
      leyenda4:"Don't worry, at the end of the camp, we will notify you if your child had any discomfort and what medication was administered."

          




    },
    "esp":{
      place1:"En caso de NO padecer ningun problema cardiaco, teclea la palabra NINGUNO",
      place2:"En caso de NO haber tenido o tener cirugías, fracturas y/o esguinces que le impidan realizar actividades, teclea la palabra NINGUNA",
      place3:"Si no requiere de tratamiento médico, teclea la palabra NINGUNO",
      place4:"En caso de que el acampador no sea alérgico a algún medicamento, teclea la palabra NINGUNO",
      place5:"En caso de que el acampador no sea alérgico, teclea la palabra NINGUNA",
      place6:"En caso de que el acampador no padezca de alteraciones nocturnas, teclea la palabra NINGUNA",
      place7:"En caso de que el acampador no padezca de fobias, teclea la palabra NINGUNO",
      place8:"En caso de NO estar en ningun tratamiento psicologico o psiquiatrico, teclea la palabra NINGUNO",
      place9:"En caso de que el acampador pueda comer cualquier tipo de comida, teclea la palabra NINGUNA",
      leyenda1:" Camper Control cuenta con seguro de gastos contra accidentes menores con compañia_seguros. En caso de que las atenciones médicas superen la cantidad asegurada ,los gastos correrán a cargo de los padres de familia o del seguro de gastos médicos mayores del acampador (en caso de contar con uno). Checa los detalles de tu camp para conocer los montos asegurados y características del seguro.",
      leyenda2:" Nadar no hace referencia a habilidades deportivas específicas sino a la capacidad de flotar y avanzar en el agua haciendo los movimientos necesarios con el cuerpo y las extremidades sin tocar el fondo ni otro apoyo de algún objeto para su desplazamiento. En caso de no saber nadar y querer participar en actividades acuáticas, será responsabilidad de los padres de familia el incluir en su equipaje un dispositivo de flotación (chaleco, flotis)" ,   
      leyenda3:"Te pedimos que selecciones los medicamentos que autorizas. Cualquier medicamento no seleccionado se considerará como NO PRE-AUTORIZADO, en cuyo caso, nos comunicaremos contigo.",
      leyenda4:"Descuida que al final del campamento, te notificaríamos si tu hij@ tuvo algún malestar y qué medicamento se le administró."

    }


  }
  public formUser : FormGroup;
  public formGen : FormGroup;
 
  vacunas:any = [];
  @ViewChild("name") name: ElementRef;
  @ViewChild("lastname_father") lastname_father: ElementRef;
  @ViewChild("lastname_mother") lastname_mother: ElementRef;
  @ViewChild("email") email: ElementRef;
  @ViewChild("gender_id") gender_id: ElementRef;
  @ViewChild("birthday") birthday: ElementRef;
  @ViewChild("school_id") school_id: ElementRef; 
  @ViewChild("grade") grade: ElementRef; 
  @ViewChild("weight") weight: ElementRef; 
  @ViewChild("height") height: ElementRef;

  @ViewChild("blood_type") blood_type: ElementRef; 
  @ViewChild("heart_problems") heart_problems: ElementRef; 
  @ViewChild("prevent_activities") prevent_activities: ElementRef; 
  @ViewChild("affliction") affliction: ElementRef; 
  @ViewChild("nocturnal_disorders") nocturnal_disorders: ElementRef; 
  @ViewChild("phobias") phobias: ElementRef; 
  @ViewChild("psicology_treatments") psicology_treatments: ElementRef; 
  @ViewChild("security_social_number") security_social_number: ElementRef; 
  @ViewChild("insurance_number") insurance_number: ElementRef;  
  @ViewChild("drugs") drugs: ElementRef; 
  @ViewChild("drug_allergies") drug_allergies: ElementRef;  
  @ViewChild("other_allergies") other_allergies: ElementRef; 
  @ViewChild("prohibited_foods") prohibited_foods: ElementRef; 
  @ViewChild("contact_relation") contact_relation: ElementRef; 
  @ViewChild("contact_name") contact_name: ElementRef; 
  @ViewChild("contact_cellphone") contact_cellphone: ElementRef; 
  @ViewChild("contact_homephone") contact_homephone: ElementRef; 
  @ViewChild("photo") photo: ElementRef;
  @ViewChild("school_other") school_other: ElementRef;
  @ViewChild("insurance_company") insurance_company: ElementRef;

  @ViewChild("terms") terms: ElementRef;
  @ViewChild("acept") acept: ElementRef;
  @ViewChild('schoolSelect', { static: false }) schoolSelect!: NgSelectComponent;


  photoSatus = false;
  spinerPhot= true;
  textos ={};
  maxDate: string = '';
edad: number | null = null;
  idioma = 'esp' 
  



  sexo:string[]=['Hombre','Mujer',"No binario"," Prefiero no decir"]

  constructor(private catalogos: CamperService , private formGrup: FormBuilder, private router:Router,private render:Renderer2,private info: AuthenticationService,private lang:LangService) {
    
    const today = new Date();
    today.setFullYear(today.getFullYear() - 3);
    this.maxDate = today.toISOString().split('T')[0];
    this.textos  = traducciones['traduciones'][this.idioma]['formUserChildren'];
    
    this.catalogos.getCatalogos().subscribe((res:any)=>{
      console.log(info.infToken);
      
      this.blood_types = res.blood_types;
      this.food_restrictions = res.food_restrictions;
      this.genders = res.genders;
      this.grades = res.grades;
      this.licensed_medicines = res.licensed_medicines;
      this.pathological_background = res.pathological_background;
      this.pathological_background_fm = res.pathological_background_fm;
      this.school = res.school;
      this.school.sort((a, b) => a.name.localeCompare(b.name));
      this.vaccines = res.vaccines;
      // Ordenar los dos arreglos alfabéticamente
// Ordenar los dos arreglos alfabéticamente por la propiedad 'name'
this.pathological_background.sort((a, b) => a.name.localeCompare(b.name));
this.pathological_background_fm.sort((a, b) => a.name.localeCompare(b.name));


      this.getInfo();

      console.log(res);     
    })


  }

  ngOnInit(): void {
    this.lang.getLang().subscribe((res:any)=>{
      this.idioma=res
      console.log(this.idioma);
      
      this.textos  = traducciones['traduciones'][this.idioma]['formUserChildren'];
    })

    this.formUser = this.formGrup.group({
      name:["",[Validators.required,Validators.minLength(2)]],
      lastname_father:["",[Validators.required,,Validators.minLength(2)]],
      lastname_mother:["",],
      photo:["",[Validators.required,Validators.minLength(2)]],
      gender_id:[0,[Validators.required,this.greaterThanZeroValidator()]],
      birthday:["",[Validators.required]],
      height: [null, [Validators.required, this.validateMaxHeight,Validators.pattern("^[0-9]*$"),this.validateNumberWithoutDecimal, Validators.min(1)]],
      weight: [null, [Validators.required, this.validateMaxHeight,Validators.pattern("^[0-9]*$"),this.validateNumberWithoutDecimal, Validators.min(1)]],
      grade:[0,[Validators.required,this.greaterThanZeroValidator()]],
      school_id:[0,[Validators.required,this.greaterThanZeroValidator()]],
      school_other:["",],
      email: [""],
      can_swim: [87],
      affliction: ["",[Validators.required]],
      blood_type: [0,[Validators.required,this.greaterThanZeroValidator()]],
      heart_problems: ["",[Validators.required,Validators.minLength(2)]],
      psicology_treatments: ["",[Validators.required,Validators.minLength(2)]],
      prevent_activities: ["",[Validators.required,Validators.minLength(2)]],
      drug_allergies: ["",[Validators.required,Validators.minLength(2)]],
      other_allergies: ["",[Validators.required,Validators.minLength(2)]],
      nocturnal_disorders: ["",[Validators.required,Validators.minLength(2)]],
      phobias: ["",[Validators.required,Validators.minLength(2)]],
      drugs: ["",[Validators.required,Validators.minLength(2)]],
      doctor_precall: [false],
      prohibited_foods: ["",[Validators.required,Validators.minLength(2)]],
      comments_admin: ["Ninguno"],
      insurance: [false],
      insurance_company: [""],
      insurance_number: [""],
      security_social_number: ["",],
      contact_name: ["",[Validators.required,Validators.minLength(2)]],
      contact_relation: ["",[Validators.required,Validators.minLength(3)]],
      contact_homephone:  ["",[Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(8), Validators.maxLength(10)]],
      contact_cellphone:  ["",[Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(8), Validators.maxLength(10)]],
      parent_id: [this.info.infToken.profile_id,[Validators.required]],
      terms: ["",[Validators.required,Validators.requiredTrue]],
    })
    
  }
  validateNumberWithoutDecimal(control: any) {
    const value = control.value;
    if (value === null || value === undefined) {
      return null; // El control está vacío, no se aplica la validación
    }
    
    // Verificar si el valor contiene un punto decimal
    if (value.toString().includes('.')) {
      return { hasDecimal: true }; // Devolver un error si contiene un punto decimal
    }
    
    return null; // Si no contiene punto decimal, la validación es exitosa
  }

  
  validateMaxHeight(control) {
    const maxHeight = 200;
    if (control.value && parseInt(control.value) > maxHeight) {
      return { 'maxHeightExceeded': true };
    }
    return null;
  }
  getInfo(){
 
    this.name.nativeElement.focus()

  
    
  }


  getname(){
    if( this.formUser.get('name').valid){
      this.render.removeClass(this.name.nativeElement,"is-invalid");
      this.render.addClass(this.name.nativeElement,"is-valid");
   }else{
    this.render.removeClass(this.name.nativeElement,"is-valid");
    this.render.addClass(this.name.nativeElement,"is-invalid");
    this.name.nativeElement.focus()

   }
    
  }

  getlastname_father()  {
    if( this.formUser.get('lastname_father').valid){
      this.render.removeClass(this.lastname_father.nativeElement,"is-invalid");
      this.render.addClass(this.lastname_father.nativeElement,"is-valid");
   }else{
    this.render.removeClass(this.lastname_father.nativeElement,"is-valid");
    this.render.addClass(this.lastname_father.nativeElement,"is-invalid");
    this.lastname_father.nativeElement.focus()

   }  
  }
  
  getlastname_mother() {
    
    if( this.formUser.get('lastname_mother').valid){
      this.render.removeClass(this.lastname_mother.nativeElement,"is-invalid");
      this.render.addClass(this.lastname_mother.nativeElement,"is-valid");
   }else{
    this.render.removeClass(this.lastname_mother.nativeElement,"is-valid");
    this.render.addClass(this.lastname_mother.nativeElement,"is-invalid");
    this.lastname_mother.nativeElement.focus()

   } 
  }
  
  getphoto() {
    if(this.formUser.get('photo').valid){
      this.photoSatus = true;
    }else{
     // this.photo.nativeElement.focus();
      console.log('ere');
      const element:any = document.getElementById("photo");
      element.scrollIntoViewIfNeeded();

      this.photoSatus= false;
    }
     
  }
  
  getgender_id() {
    if( this.formUser.get('gender_id').valid){
      console.log( this.formUser.get('gender_id').valid);
      
      this.render.removeClass(this.gender_id.nativeElement,"is-invalid");
      this.render.addClass(this.gender_id.nativeElement,"is-valid");
   }else{
    this.render.removeClass(this.gender_id.nativeElement,"is-valid");
    this.render.addClass(this.gender_id.nativeElement,"is-invalid");
    this.gender_id.nativeElement.focus()

   }
  }
  
  getbirthday() {
    const birthdate = this.formUser.get('birthday')?.value;
    if (birthdate) {
      const birth = new Date(birthdate);
      const today = new Date();
      let age = today.getFullYear() - birth.getFullYear();
      const m = today.getMonth() - birth.getMonth();
  
      if (m < 0 || (m === 0 && today.getDate() < birth.getDate())) {
        age--;
      }
  
      this.edad = age;
    } else {
      this.edad = null;
    }
    
    if( this.formUser.get('birthday').valid){
      this.render.removeClass(this.birthday.nativeElement,"is-invalid");
      this.render.addClass(this.birthday.nativeElement,"is-valid");
   }else{
    this.render.removeClass(this.birthday.nativeElement,"is-valid");
    this.render.addClass(this.birthday.nativeElement,"is-invalid");
    this.birthday.nativeElement.focus()

   }
  }
  
  getheight() {
    if( this.formUser.get('height').valid){
      this.render.removeClass(this.height.nativeElement,"is-invalid");
      this.render.addClass(this.height.nativeElement,"is-valid");
   }else{
    this.render.removeClass(this.height.nativeElement,"is-valid");
    this.render.addClass(this.height.nativeElement,"is-invalid");
    this.height.nativeElement.focus()

   }
  }
  
  getweight() {
    if( this.formUser.get('weight').valid){
      this.render.removeClass(this.weight.nativeElement,"is-invalid");
      this.render.addClass(this.weight.nativeElement,"is-valid");
   }else{
    this.render.removeClass(this.weight.nativeElement,"is-valid");
    this.render.addClass(this.weight.nativeElement,"is-invalid");
    this.weight.nativeElement.focus()

   }
  }
  
  getgrade() {
    if( this.formUser.get('grade').valid){
      this.render.removeClass(this.grade.nativeElement,"is-invalid");
      this.render.addClass(this.grade.nativeElement,"is-valid");
   }else{
    this.render.removeClass(this.grade.nativeElement,"is-valid");
    this.render.addClass(this.grade.nativeElement,"is-invalid");
    this.grade.nativeElement.focus()

   }
  }
  
  getschool_id() {
    const control = this.formUser.get('school_id');

  if (control?.valid) {
    // Solo clases, si gustas
  } else {
    // Marcar como tocado para que se activen los estilos
    control?.markAsTouched();

    // Enfocar ng-select
    this.schoolSelect.focus();
  }
  }
  
  getSchool_other() {
    if( this.formUser.get('school_other').valid){
      this.render.removeClass(this.school_other.nativeElement,"is-invalid");
      this.render.addClass(this.school_other.nativeElement,"is-valid");
   }else{
    this.render.removeClass(this.school_other.nativeElement,"is-valid");
    this.render.addClass(this.school_other.nativeElement,"is-invalid");
    this.school_other.nativeElement.focus()

   }  }
  
  getemail() {
   
    if( this.formUser.get('email').valid){
      this.render.removeClass(this.email.nativeElement,"is-invalid");
      this.render.addClass(this.email.nativeElement,"is-valid");
   }else{
    this.render.removeClass(this.email.nativeElement,"is-valid");
    this.render.addClass(this.email.nativeElement,"is-invalid");
    this.email.nativeElement.focus()

   } 
  }

  
  
  get can_swim() {
    return this.formUser.get('can_swim')  ;
  }
  
  getaffliction() {
    if( this.formUser.get('affliction').valid){
      this.render.removeClass(this.affliction.nativeElement,"is-invalid");
      this.render.addClass(this.affliction.nativeElement,"is-valid");
   }else{
    this.render.removeClass(this.affliction.nativeElement,"is-valid");
    this.render.addClass(this.affliction.nativeElement,"is-invalid");
    this.affliction.nativeElement.focus()

   }
  }
  
  getblood_type() {
    if( this.formUser.get('blood_type').valid){
      this.render.removeClass(this.blood_type.nativeElement,"is-invalid");
      this.render.addClass(this.blood_type.nativeElement,"is-valid");
   }else{
    this.render.removeClass(this.blood_type.nativeElement,"is-valid");
    this.render.addClass(this.blood_type.nativeElement,"is-invalid");
    this.blood_type.nativeElement.focus()

   }
  }
  
  getheart_problems() {
    if( this.formUser.get('heart_problems').valid){
      this.render.removeClass(this.heart_problems.nativeElement,"is-invalid");
      this.render.addClass(this.heart_problems.nativeElement,"is-valid");
   }else{
    this.render.removeClass(this.heart_problems.nativeElement,"is-valid");
    this.render.addClass(this.heart_problems.nativeElement,"is-invalid");
    this.heart_problems.nativeElement.focus()

   }
  }
  
  getpsicology_treatments() {
    if( this.formUser.get('psicology_treatments').valid){
      this.render.removeClass(this.psicology_treatments.nativeElement,"is-invalid");
      this.render.addClass(this.psicology_treatments.nativeElement,"is-valid");
   }else{
    this.render.removeClass(this.psicology_treatments.nativeElement,"is-valid");
    this.render.addClass(this.psicology_treatments.nativeElement,"is-invalid");
    this.psicology_treatments.nativeElement.focus()

   }
  }
  
  getprevent_activities() {
    if( this.formUser.get('prevent_activities').valid){
      this.render.removeClass(this.prevent_activities.nativeElement,"is-invalid");
      this.render.addClass(this.prevent_activities.nativeElement,"is-valid");
   }else{
    this.render.removeClass(this.prevent_activities.nativeElement,"is-valid");
    this.render.addClass(this.prevent_activities.nativeElement,"is-invalid");
    this.prevent_activities.nativeElement.focus()

   }
  }
  
  getdrug_allergies() {
    if( this.formUser.get('drug_allergies').valid){
      this.render.removeClass(this.drug_allergies.nativeElement,"is-invalid");
      this.render.addClass(this.drug_allergies.nativeElement,"is-valid");
   }else{
    this.render.removeClass(this.drug_allergies.nativeElement,"is-valid");
    this.render.addClass(this.drug_allergies.nativeElement,"is-invalid");
    this.drug_allergies.nativeElement.focus()

   }
  
  }
  
  getother_allergies() {
    if( this.formUser.get('other_allergies').valid){
      this.render.removeClass(this.other_allergies.nativeElement,"is-invalid");
      this.render.addClass(this.other_allergies.nativeElement,"is-valid");
   }else{
    this.render.removeClass(this.other_allergies.nativeElement,"is-valid");
    this.render.addClass(this.other_allergies.nativeElement,"is-invalid");
    this.other_allergies.nativeElement.focus()

   }
  }
  
  getnocturnal_disorders() {
    if( this.formUser.get('nocturnal_disorders').valid){
      this.render.removeClass(this.nocturnal_disorders.nativeElement,"is-invalid");
      this.render.addClass(this.nocturnal_disorders.nativeElement,"is-valid");
   }else{
    this.render.removeClass(this.nocturnal_disorders.nativeElement,"is-valid");
    this.render.addClass(this.nocturnal_disorders.nativeElement,"is-invalid");
    this.nocturnal_disorders.nativeElement.focus()

   }
  }
  
  getphobias() {
    if( this.formUser.get('phobias').valid){
      this.render.removeClass(this.phobias.nativeElement,"is-invalid");
      this.render.addClass(this.phobias.nativeElement,"is-valid");
   }else{
    this.render.removeClass(this.phobias.nativeElement,"is-valid");
    this.render.addClass(this.phobias.nativeElement,"is-invalid");
    this.phobias.nativeElement.focus()

   }
  }
  
  getdrugs() {
    if( this.formUser.get('drugs').valid){
      this.render.removeClass(this.drugs.nativeElement,"is-invalid");
      this.render.addClass(this.drugs.nativeElement,"is-valid");
   }else{
    this.render.removeClass(this.drugs.nativeElement,"is-valid");
    this.render.addClass(this.drugs.nativeElement,"is-invalid");
    this.drugs.nativeElement.focus()

   }
    
  }
  
  get doctor_precall() {
    return this.formUser.get('doctor_precall')  ;
  }
  
  getprohibited_foods() {
    if( this.formUser.get('prohibited_foods').valid){
      this.render.removeClass(this.prohibited_foods.nativeElement,"is-invalid");
      this.render.addClass(this.prohibited_foods.nativeElement,"is-valid");
   }else{
    this.render.removeClass(this.prohibited_foods.nativeElement,"is-valid");
    this.render.addClass(this.prohibited_foods.nativeElement,"is-invalid");
    this.prohibited_foods.nativeElement.focus()

   }
   
  }
  
  getcomments_admin() {
    return this.formUser.get('comments_admin')  ;
  }
  
  getinsurance() {
    return this.formUser.get('insurance')  ;
  }
  
  getinsurance_company() {
  
    if( this.formUser.get('insurance_company').valid){
      this.render.removeClass(this.insurance_company.nativeElement,"is-invalid");
      this.render.addClass(this.insurance_company.nativeElement,"is-valid");
   }else{
    this.render.removeClass(this.insurance_company.nativeElement,"is-valid");
    this.render.addClass(this.insurance_company.nativeElement,"is-invalid");
    this.insurance_company.nativeElement.focus()

   }
  }
  
  getinsurance_number() {
    if( this.formUser.get('insurance_number').valid){
      this.render.removeClass(this.insurance_number.nativeElement,"is-invalid");
      this.render.addClass(this.insurance_number.nativeElement,"is-valid");
   }else{
    this.render.removeClass(this.insurance_number.nativeElement,"is-valid");
    this.render.addClass(this.insurance_number.nativeElement,"is-invalid");
    this.insurance_number.nativeElement.focus()

   }
  }
  
  getsecurity_social_number() {
    if( this.formUser.get('security_social_number').valid){
      this.render.removeClass(this.security_social_number.nativeElement,"is-invalid");
      this.render.addClass(this.security_social_number.nativeElement,"is-valid");
   }else{
    this.render.removeClass(this.security_social_number.nativeElement,"is-valid");
    this.render.addClass(this.security_social_number.nativeElement,"is-invalid");
    this.security_social_number.nativeElement.focus()

   }
  }
  
  getcontact_name() {
    if( this.formUser.get('contact_name').valid){
      this.render.removeClass(this.contact_name.nativeElement,"is-invalid");
      this.render.addClass(this.contact_name.nativeElement,"is-valid");
   }else{
    this.render.removeClass(this.contact_name.nativeElement,"is-valid");
    this.render.addClass(this.contact_name.nativeElement,"is-invalid");
    this.contact_name.nativeElement.focus()

   }
  }
  
  getcontact_relation() {
    if( this.formUser.get('contact_relation').valid){
      this.render.removeClass(this.contact_relation.nativeElement,"is-invalid");
      this.render.addClass(this.contact_relation.nativeElement,"is-valid");
   }else{
    this.render.removeClass(this.contact_relation.nativeElement,"is-valid");
    this.render.addClass(this.contact_relation.nativeElement,"is-invalid");
    this.contact_relation.nativeElement.focus()

   }
  }

 
  getcontact_homephone() {
    if( this.formUser.get('contact_homephone').valid){
      this.render.removeClass(this.contact_homephone.nativeElement,"is-invalid");
      this.render.addClass(this.contact_homephone.nativeElement,"is-valid");
   }else{
    this.render.removeClass(this.contact_homephone.nativeElement,"is-valid");
    this.render.addClass(this.contact_homephone.nativeElement,"is-invalid");
    this.contact_homephone.nativeElement.focus()

   }
  }
  
  getcontact_cellphone() {
    if( this.formUser.get('contact_cellphone').valid){
      this.render.removeClass(this.contact_cellphone.nativeElement,"is-invalid");
      this.render.addClass(this.contact_cellphone.nativeElement,"is-valid");
   }else{
    this.render.removeClass(this.contact_cellphone.nativeElement,"is-valid");
    this.render.addClass(this.contact_cellphone.nativeElement,"is-invalid");
    this.contact_cellphone.nativeElement.focus()

   }
  }
  
  getTerminos() {
    if(! this.formUser.get('terms').valid){
      this.render.addClass(this.acept.nativeElement,"text-danger");
      this.render.addClass(this.terms.nativeElement,"text-danger");
      alert('Aún no aceptas los términos y condiciones');


   }
  }

 
  subiendo(event: any) {
    this.spinerPhot = false;
  
    const archivo = event.target.files[0];
  
    if (archivo) {
      const reader = new FileReader();
  
      reader.onload = (e: any) => {
        const img = new Image();
        img.src = e.target.result;
  
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
  
          // Verificar si la imagen es horizontal
          if (img.width > img.height) {
            canvas.width = img.height;
            canvas.height = img.width;
            ctx.rotate((90 * Math.PI) / 180); // Rotar 90 grados
            ctx.translate(0, -canvas.width);
          } else {
            canvas.width = img.width;
            canvas.height = img.height;
          }
  
          // Dibujar la imagen en el canvas
          ctx.drawImage(img, 0, 0);
  
          // Mostrar la imagen procesada en el avatar
          this.photoSelect = canvas.toDataURL('image/jpeg');
  
          // Convertir canvas a Blob y enviar al servidor
          canvas.toBlob((blob: Blob) => {
            const archivoProcesado = new File([blob], archivo.name, { type: 'image/jpeg' });
  
            const formulario = new FormData();
            formulario.append('file', archivoProcesado);
  
            this.catalogos.setPhoto(formulario).subscribe(
              (res: any) => {
                console.log(res.path);
  
                // Actualizar la ruta de la imagen en el formulario
                this.formUser.patchValue({
                  photo: res.path,
                });
                this.photoSatus = true;
                this.spinerPhot = true;
              },
              error => {
                console.error(error);
                this.photoSatus = false;
              }
            );
          }, 'image/jpeg');
        };
      };
  
      reader.readAsDataURL(archivo);
    }
  }
  



public fileOver(event){
  console.log(event);
}

public fileLeave(event){
  console.log(event);

}

  
  prueba1(){
    this.spinner=true;
    if(this.formUser.valid){
      let a ={ 
        "camper":this.formUser.value,
        "vaccines": this.vaccines,
        "licensed_medicines": this.licensed_medicines,
        "food_restrictions": this.food_restrictions,
        "pathological_background":this.pathological_background,
        "pathological_background_fm": this.pathological_background_fm
  
      }
      console.log(a);
      
      this.catalogos.setCamper(a).subscribe((res:any)=>{
          console.log(res);
          if(res.succes = 200){
            this.spinner=false;
           this.router.navigate(['dashboard/parents/inscription/'+res.camper_id]);
          }
          
      });
    }else{
      this.spinner=false;
      this.getinsurance_company()
      this.getsecurity_social_number();
      this.getinsurance_number();
      this.getcontact_homephone();
      this.getcontact_cellphone();
      this.getcontact_relation();
      this.getcontact_name();
      this.getprohibited_foods();
      this.getpsicology_treatments()
      this.getphobias();
      this.getnocturnal_disorders();
      this.getother_allergies();
      this.getdrug_allergies()
      this.getdrugs();
      this.getaffliction();
      this.getprevent_activities();
      this.getheart_problems();
      this.getblood_type();
      this.getweight();
      this.getheight();
      this.getgrade();
      this.getSchool_other();
      this.getschool_id();
      this.getphoto();
      this.getbirthday();
      this.getgender_id();
      this.getemail();
      this.getlastname_mother();
      this.getlastname_father();
      this.getname();
      this.getTerminos();
     
    
     

    }

    
  }
  prueba2(){
    console.log(this.foto);
    
    this.catalogos.setPhoto(this.foto).subscribe((res:any)=>{
      console.log(res);
      
    })
  }

   greaterThanZeroValidator() {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value === null || value === undefined || isNaN(value) || value <= 0) {
        return { greaterThanZero: true };
      }
      return null;
    };
  }
 
  cancelar(){
    this.router.navigate(["dashboard/parents"])
  }
 
 

  licensed_medicinesValu(i:any){
    this.licensed_medicines[i].is_active =! this.licensed_medicines[i].is_active;
  }

  pathological_background_fmValu(i:any){
    this.pathological_background_fm[i].is_active =! this.pathological_background_fm[i].is_active;
  }
  pathological_backgroundValu(i:any){
    this.pathological_background[i].is_active =! this.pathological_background[i].is_active;
  }
  food_restrictionsValu(i:any){
    this.food_restrictions[i].is_active =! this.food_restrictions[i].is_active;
  }
  vaccinesValu(i:any){
    this.vaccines[i].is_active =! this.vaccines[i].is_active;
  }
}
