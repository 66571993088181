<div class="d-flex justify-content-center" *ngIf="!cargando">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div class="container-fluid" *ngIf="cargando && !cargosExtr">
  <div class="row">
    <div *ngIf="rol <3"class="row">
      <div class="col-lg-6 col-sm-12 col-md-6">
        <div class="card" *ngIf="statusInscri">
          <div class="bodyCamper">
            <h1 style="color:white ;">{{textos[idoma]["texto11"]}} </h1>
          </div>
          <div class="mt-5px container-pagos p-2" *ngIf="pagos">
            <h1>{{textos[idoma]["texto12"]}} ${{balance}} MXN</h1>
          </div>
          <div class="col-lg-12">
            <div class="card p-2">
              <div class="card-body">
  
                <p class="card-title-title" style="color: rgb(226, 77, 77);" *ngIf="dataCamp.show_payment_order" >
                  {{textos[idoma]["texto13"]}}
                </p>
                <div class="row">
  
  
                </div>
             
                
                <div *ngIf="rol== 1">
                  <div class="table-responsive" *ngIf="pagos && dataCamp.show_payment_parent ">
  
                    <table class="table mb-0">
    
                      <thead class="thead-dark">
                        <tr>
                          <th>#</th>
                          <th>{{textos[idoma]["th"]}} </th>
                          <th>{{textos[idoma]["th1"]}} </th>
                          <th>{{textos[idoma]["th2"]}} </th>
                          <th>{{textos[idoma]["th3"]}} </th>
                          <th>{{textos[idoma]["th4"]}} </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of dataPagos">
    
                          <td>{{item.id}}</td>
                          <td>{{item.payment_date.substring(0,10)}}</td>
                          <td>{{item.txn_number}}</td>
                          <td>
                            <div *ngIf="item.payment_amount>=0">
                              ${{item.payment_amount}}MXN
                            </div>
                            <div *ngIf="item.payment_amount<0">
    
                            </div>
                          </td>
                          <td>
                            <div *ngIf="item.payment_amount<0">
                              ${{item.payment_amount*-1}}MXN
                            </div>
                            <div *ngIf="item.payment_amount>=0">
    
                            </div>
                          </td>
                          <td>${{item.saldo}}MXN</td>
                        </tr>
    
    
                      </tbody>
                    </table>
                  </div>
                  
                  
                </div>

<div *ngIf="rol == 2">

  <p-tabView>
    <p-tabPanel header="Pagos">
      <div>
        <div class="table-responsive" >
  
          <table class="table mb-0">

            <thead class="thead-dark">
              <tr>
                <th>#</th>
                <th>{{textos[idoma]["th"]}} </th>
                <th>{{textos[idoma]["th1"]}} </th>
                <th>{{textos[idoma]["th2"]}} </th>
                <th>{{textos[idoma]["th3"]}} </th>
                <th>{{textos[idoma]["th4"]}} </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of dataPagos">

                <td>{{item.id}}</td>
                <td>{{item.payment_date.substring(0,10)}}</td>
                <td>{{item.txn_number}}</td>
                <td>
                  <div *ngIf="item.payment_amount>=0">
                    ${{item.payment_amount}}MXN
                  </div>
                  <div *ngIf="item.payment_amount<0">

                  </div>
                </td>
                <td>
                  <div *ngIf="item.payment_amount<0">
                    ${{item.payment_amount*-1}}MXN
                  </div>
                  <div *ngIf="item.payment_amount>=0">

                  </div>
                </td>
                <td>${{item.saldo}}MXN</td>
              </tr>


            </tbody>
          </table>
        </div>
        
        
      </div>    </p-tabPanel>
    <p-tabPanel header="Pagos Mercado pago">
      <table class="table table-bordered table-striped" *ngIf="dataCamp.show_mercadopago_button">
        <thead>
          <tr>
            <th scope="col">Descripción</th>
            <th scope="col">Fecha de Creación</th>
            <th scope="col">Estatus del Pago</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let payment of paymentsMp">
            <td>{{ payment.additional_info.items[0]?.description }}</td>
            <td>{{ payment.date_created | date: 'dd/MM/yyyy HH:mm:ss' }}</td>
            <td [ngClass]="getStatusClass(payment.status)">
              {{ mapStatus(payment.status) }}
            </td>
          </tr>
        </tbody>
      </table>
    </p-tabPanel>
  
</p-tabView>

</div>

                <div *ngIf="!pagos">
                  <div class="alert alert-success" role="alert">
                    {{textos[idoma]["texto14"]}}
                  </div>
                </div>
  
              </div>
              <div *ngIf="dataCamp.show_payment_order" style="display: flex; align-items: center;">
                <button class="btn  btn-success p-2" style="height:auto; width: 70%; margin: 0 auto;" (click)="getBaucher()"  
                *ngIf="dataCamp.show_payment_parent" #baucher>Ficha de Pagos</button>
              </div>
              <div  style="display: flex; align-items: center; flex-direction: column;" *ngIf="dataCamp.show_mercadopago_button">
                <div>
                  <p class="fs-5">Realiza tu pago ,
                    Haz clic en el botón de Mercado Pago para continuar:</p>
                </div>
  
                <div class="alert alert-info" role="alert" *ngIf="dataCamp.show_mercadopago_button">
                  <h4 class="alert-heading">Formas de pago aceptadas por Mercado Pago</h4>
                  <p>Mercado Pago acepta las siguientes formas de pago:</p>
                  <ul>
                    <li>Tarjeta de crédito</li>
                    <li>Tarjeta de débito</li>
                    <li>Efectivo</li>
                    <li>Dinero en Mercado Pago</li>
                    <li>Transferencia electrónica</li>
                  </ul>
                  <hr>
                  <p class="mb-0">Utiliza cualquiera de estos métodos para realizar tu pago de manera segura y rápida.</p>
                </div> 
                  <div *ngIf="dataCamp.show_mercadopago_button">
                    <p> <a class="mercado-pago-btn " (click)="showMercadopago =!showMercadopago"  ><img src="https://img.icons8.com/?size=100&id=nTLVtpxsNPaz&format=png&color=000000"  height="25px"> Haz clic aqui</a></p>
                  </div>
  
            </div>
  
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg- col-sm-12 col-md-6"  >
        <div class="card" *ngIf="statusInscri">
          <div class="bodyCamper2">
            <h3 style="color:white ;" class="mt-2">Fechas de pago recomendadas</h3>
          </div>
        
        
          <div class="col-lg-12">
            <div class="card">
              <div class="card-body">
  
  
                <div class="table-responsive"  >
  
                  <table class="table table-bordered table-striped mb-0">
  
                    <thead >
                      <tr>
                        
                        <th>{{textos[idoma]["th"]}} </th>
                        <th>Cantidad a pagar</th>
                      
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of fechadePagos">
                        
                        <td>{{item.name.substring(0,10) | date: 'dd MMMM yyyy'  }}</td>
                        <td>{{item.price| currency:'USD':'':'1.2-2'}}</td>
                       
                      </tr>
  
  
                    </tbody>
                  </table>
                </div>
       
            
              
    
              
  
              </div>
              
  
            </div>
          </div>
        </div>
      </div>

    </div>
   



    <div class="col-lg-12">
      <div class="card p-2">
        <div class="bodyCamper1">

          <h1 style="color:white ;">{{textos[idoma]["titulo"]}} {{dataCamp.name}}</h1>
        </div>
        <div class="mt-3 ms-3 container-pagos">
          <h1>{{dataCamp.name}}</h1>
        </div>
        <hr>
        <div class="col-lg-12">
          <div class="card">
            <div class="conatiner-info-camp">
              <div>
                <p class="fw-bold"> <i class="fas fa-calendar-alt
                          "> </i> {{textos[idoma]["texto1"]}} </p>
              </div>
              <div>
                <p> {{dataCamp.start.substring(0,10)}} - {{dataCamp.end.substring(0,10)}}</p>
              </div>
            </div>
            <div class="conatiner-info-camp">
              <div>
                <p class="fw-bold"> <i class="bx bxs-time"> </i> {{textos[idoma]["texto2"]}} </p>
              </div>
              <div>
                <p> {{dataCamp.start.substring(0,10)}} : {{dataCamp.start.substring(14,19)}}</p>
              </div>
            </div>
            <div class="conatiner-info-camp">
              <div>
                <p class="fw-bold"> <i class="bx bxs-time"> </i> {{textos[idoma]["texto3"]}} </p>
              </div>
              <div>
                <p> {{dataCamp.end.substring(0,10)}} : {{dataCamp.end.substring(14,19)}}</p>
              </div>
            </div>
            <div class="conatiner-info-camp">
              <div>
                <p class="fw-bold"> <i class="fas fa-map-marked-alt"> </i>{{textos[idoma]["texto4"]}} </p>
              </div>
              <div>
                <p> {{dataCamp.venue}}</p>
              </div>
            </div>
            <div class="conatiner-info-camp">
              <div>
                <p class="fw-bold"> <i class="fas fa-map"> </i> {{textos[idoma]["texto5"]}} </p>
              </div>
              <div>
                <p> {{location}}</p>
              </div>
            </div>
            <div class="conatiner-info-camp">
              <div>
                <p class="fw-bold"> <i class="fas fa-map"> </i> {{textos[idoma]["texto8"]}} </p>
              </div>
              <div>
                <p> {{dataCamp.insurance}}</p>
              </div>
            </div>
            <div class="conatiner-info-camp">
              <div>
                <p class="fw-bold"> <i class="bx bxs-camera-plus"> </i> {{textos[idoma]["texto10"]}} </p>
              </div>
              <div>
                <p> <a href={{dataCamp.photo_url}}> Ver fotos</a></p>
              </div>
            </div>
            <div class="conatiner-info-camp">
              <div>
                <p class="fw-bold"> <i class="bx bx-globe"> </i> {{textos[idoma]["texto6"]}} </p>
              </div>
              <div>
                <p> <a href={{dataCamp.url}}> {{dataCamp.url}}</a></p>
              </div>
            </div>
            <div class="conatiner-info-camp">
              <div>
                <p class="fw-bold"> <i class="bx bx-key"> </i> {{textos[idoma]["texto7"]}} </p>
              </div>
              <div>
                <p> {{dataCamp.photo_password}}</p>
              </div>
            </div>
            <div class="conatiner-info-camp">
              <div>
                <p class="fw-bold"> <i class="bx bx-key"> </i> Mensaje especial</p>
              </div>
              <div>
                <p> {{removeHtmlTags(dataCamp.special_message)}}</p>
              </div>
            </div>
            <div class="conatiner-info-camp" *ngIf="dataCamp.show_paypal_button">
              <div>
                <p class="fw-bold"> <i class="bx bxl-paypal"> </i> Paypal</p>
              </div>
              <div>
                <p> <a href={{dataCamp.paypal_button}}>Paypal</a></p>
              </div>

            </div>

            <div class="conatiner-info-camp" *ngIf="dataCamp.show_mercadopago_button">
              <div>
                <p class="fw-bold"><img src="https://img.icons8.com/?size=100&id=nTLVtpxsNPaz&format=png&color=000000"  height="25px"/> Mercado pago</p>
              </div>
              <div >
                <p> <a class="mercado-pago-btn " (click)="showMercadopago =!showMercadopago"  ><img src="https://img.icons8.com/?size=100&id=nTLVtpxsNPaz&format=png&color=000000"  height="25px"> Mercado pago </a></p>
              </div>

            </div>



          </div>
          <hr>

          <div class="conatiner-info-camp">
            <div>
              <p class="fw-bold"> <i class="far fa-money-bill-alt"> </i>{{textos[idoma]["texto9"]}} </p>
            </div>
            <div>
              <p> ${{dataCamp.public_price}}MXN.</p>
            </div>

            <div class="cho-container"></div>

          </div>
          <div class="container-btn">
            <button class="btn  btn-info p-2" style="height:60px;" (click)="incio()">Inicio</button>
            <button class="btn  btn-secondary p-2" style="height:60px;" (click)="perfil()">Perfil acampador</button>
            <button class="btn  btn-success p-2" style="height:60px;" (click)="inscribirCamps()"
              *ngIf="!statusInscri">Inscribir</button>

            <button class="btn  btn-success p-2" style="height:60px;" (click)="open(content)" *ngIf="statusInscri">ver
              respuestas</button>
            <button class="btn  btn-info p-2" style="height:60px;" (click)="open(content2)"
              *ngIf="statusInscri">servicios adicionales</button>
            <button class="btn  btn-danger p-2" style="height:60px;" (click)="showCancelDialog= !showCancelDialog" *ngIf="statusInscri">cancelar
              inscripcion</button>
          </div>

        </div>
      </div>
    </div>


   
  </div>
</div>




<app-update-perfil *ngIf="cargando && cargosExtr" [typeSucribe]="typeSucribe" (inscribirCamps)="suscribeCamps($event)">

</app-update-perfil>






<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Preguntas Extras</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="mb-3" *ngFor="let item of PreguntasExtras; let a = index">

      <div>
        <label for="dateOfBirth">{{item.question}}</label>
        <div class="input-group">
          <input class="form-control" type="text" [(ngModel)]="PreguntasExtras[a].answer" name="answer" />
        </div>
      </div>


    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="saveRes()" *ngIf="!spinner">Guardar</button>
    <div class="spinner-border" role="status" *ngIf="spinner">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>


<ng-template #content2 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Cargos Extras</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="mb-3" *ngFor="let item of cargosExtras let index = index;">
      <label for="dateOfBirth">{{item.extra_charge_name}} </label>
      <br>
      <input class="form-check-input" type="checkbox" value="true" id="flexCheckIndeterminate"
        [(ngModel)]="cargosExtras[index].extra_selected" name="extra_selected">

      <label class="form-check-label" for="flexCheckIndeterminate">
        ${{item.extra_charge_price}}
      </label>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="saveChange()" *ngIf="!spinner">Guardar</button>


    <div class="spinner-border" role="status" *ngIf="spinner">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>


<p-dialog [(visible)]="displayModal" [modal]="true" [style]="{width: '90vw'}" [draggable]="false" [resizable]="false"
  [closable]="false"
  [responsiveOptions]="dialogResponsiveOptions">

  >
  <div class="container-fluid">
    <div class="card col-12 border border-info border-1">
      <div class="bg-info p-2">
        <h1 class="card-title text-white">Preguntas y cargos extras </h1>
      </div>
      <div class="mt-3" style="margin: 0 auto;">
        <div class="col-11 container">
          <div class="alert alert-warning col-12" role="alert">
            <strong>Hola,</strong> No te preocupes, estamos a punto de terminar tu inscripción. Solo necesitamos que
            respondas algunas preguntas y confirmes algunos cargos adicionales. ¡Gracias por tu paciencia!
          </div>
        </div>


        <div class="container col-11  mt-5">
          <div class="">
            <h3 class="text-center ">Preguntas extras</h3>
            <div class="form-group" *ngFor="let pregunta of extra_questions; let i = index">
              <label for="pregunta{{ i }}"> {{ pregunta.question }} <span
                  style="color:rgb(52, 52, 54); font-weight: 800;"> ( {{ pregunta.camp_name }})</span> :</label>
              <input type="text" class="form-control" id="pregunta{{ i }}" [(ngModel)]="extra_questions[i].answer"
                name="answer">
            </div>
          </div>

        </div>



        <div class="container mt-5 col-11" *ngIf="extra_charges.length>0">
          <h3 class="text-center">Cargos extras</h3>
          <div class="list-group" *ngFor="let extra_charge of extra_charges; let i = index">
            <label class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
              <div>
                <input type="checkbox" class="form-check-input me-3" id="cargo{{ i }}"
                  name="camp_extra_charge_is_selected" [(ngModel)]="extra_charges[i].camp_extra_charge_is_selected">
                {{ extra_charge.camp_extra_charge_name }} <span style="color:rgb(52, 52, 54); font-weight: 800;"> ({{
                  extra_charge.camp_name }})</span>
              </div>
              <span>{{ extra_charge.camp_extra_charge_price | currency }}</span>
            </label>
          </div>
        </div>




        <div class="container col-10 mb-2">
          <button style="width: 100%;" class="btn btn-primary " (click)="enviarCargosPreguntas()">Enviar</button>
          
        </div>
      </div>
    </div>

  </div>
</p-dialog>



<p-dialog [(visible)]="showMercadopago" [modal]="true" [style]="{width: '90vw'}" [draggable]="false" [resizable]="false"

[responsiveOptions]="dialogResponsiveOptions">


  <div class="container-fluid" >
    <div class="card col-12 border border-info border-1">
      <div class="bg-info p-2">
        <h1 class="card-title text-white">Pago </h1>
      </div>
      <div class="mt-3" style="margin: 0 auto;">
        <div class="col-11 container">
          <div class="alert alert-warning col-12" role="alert">
            El costo total de tu campamento es de {{balance | currency}}. Puedes ingresar la cantidad que deseas pagar en este momento. Recuerda que algunos campamentos tienen pagos recomendados en fechas específicas, los cuales puedes seguir si así lo deseas.

            Cualquier monto que ingreses será aplicado directamente a tu saldo pendiente.
          </div>
        </div>


        <div class="col-8 mx-auto">
          <input type="number" class="form-control  mb-4" [(ngModel)]="pagoMercado"
        name="answer">
        </div>


        <div class="col-10 mx-auto mb-3">
            <p class="text-center">Fechas de pagos recomendadas</p>
            <div class="table-responsive"   >

              <table class="table table-bordered table-striped mb-0">

                <thead >
                  <tr>
                    
                    <th>{{textos[idoma]["th"]}} </th>
                    <th>Cantidad a pagar</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of fechadePagos">
                    
                    <td>{{item.name.substring(0,10) | date: 'dd MMMM yyyy'  }}</td>
                    <td>{{item.price| currency:'USD':'':'1.2-2'}}</td>
                   
                  </tr>


                </tbody>
              </table>
            </div>

        </div>
        


       




        <div class="container col-5 mb-2">
          <button style="width: 100%;" class="btn btn-warning " (click)="linkMp()" >Pagar</button>

        </div>
      </div>
    </div>

  </div>
</p-dialog>

<p-dialog [(visible)]="showCancelDialog" [modal]="true" [style]="{width: '50vw'}"
  [draggable]="false" [resizable]="false" [responsiveOptions]="dialogResponsiveOptions" [closable]="false">
  <div class="container-fluid">
    <div class="card col-12 border border-danger border-1">
      <div class="bg-danger p-2">
        <h1 class="card-title text-white">Cancelar participación</h1>
      </div>
      <div class="mt-3 text-center" style="margin: 0 auto;">
        <div class="alert alert-warning col-10 mx-auto" role="alert">
          Estás a punto de cancelar tu participación en el campamento. <br>
          Esta acción no se puede deshacer.<br><br>
          ¿Estás seguro de que deseas continuar?
        </div>

        <div class="d-flex justify-content-center gap-3 mt-4 mb-4">
          <button class="btn btn-danger" (click)="deletCamp()">Sí, cancelar</button>
          <button class="btn btn-secondary" (click)="showCancelDialog = false">No, regresar</button>
        </div>
      </div>
    </div>
  </div>
</p-dialog>
